/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
html.projectspage main {
  margin-bottom: 0;
}
@media (max-width: 840px) {
  html.projectspage main {
    margin-left: 0;
    margin-right: 0;
  }
  html.projectspage footer {
    padding-left: 0;
    padding-right: 0;
  }
}

.projectslisting {
  margin-top: -60px;
  background-color: var(--color-blue-lighter);
  border-radius: 0 0 8px 8px;
  padding: 0 30px 60px;
}
.projectslisting__inner {
  display: flex;
  justify-content: space-between;
}
.projectslisting__filters {
  flex: none;
  width: 220px;
  margin-right: 30px;
  position: relative;
}
.projectslisting__projects {
  flex: 1 1 auto;
  max-width: calc(100% - 250px);
}
.projectslisting__projects__status {
  margin-top: 50px;
}
.projectslisting__projects__status h3 {
  margin-bottom: 30px;
}
.projectslisting__filterform__wrapper {
  margin-top: 70px;
  position: sticky;
  top: 70px;
  left: 0;
  width: 100%;
}
.projectslisting__filterform__title {
  font-size: 22px;
  margin-bottom: 15px;
}
.projectslisting__filterform .wh-form__pulldown-styled {
  margin-bottom: 10px;
}
.projectslisting__filterform .wh-form__pulldown-styled select {
  border: 1px solid rgba(30, 17, 127, 0.1);
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
}
.projectslisting__filterform__status {
  margin-top: 50px;
}
.projectslisting__filterform__status .projectpage__status__list {
  margin-top: 0;
}
.projectslisting__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-left: -30px;
  flex-wrap: wrap;
}
.projectslisting__item {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding: 0 0 30px 30px;
}
.projectslisting__item__link {
  display: block;
  position: relative;
  text-decoration: none;
  color: #fff;
  width: 100%;
  border-radius: 8px;
}
.projectslisting__item__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: 8px;
}
.projectslisting__item__link::after {
  display: block;
  content: "";
  padding-top: 140%;
}
.projectslisting__item__title {
  position: absolute;
  font-size: 17px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 30px 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 840px) {
  .projectslisting__item__title {
    font-size: 2.0238095238vw;
  }
}
@media (max-width: 691.7647058824px) {
  .projectslisting__item__title {
    font-size: 14px;
  }
}
.projectslisting__item__label {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
  line-height: 160%;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 8px 0 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
@media (max-width: 840px) {
  .projectslisting__item__label {
    font-size: 1.9047619048vw;
  }
}
@media (max-width: 682.5px) {
  .projectslisting__item__label {
    font-size: 13px;
  }
}
.projectslisting__filterstoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: none;
  right: 30px;
  font-size: 15px;
  position: absolute;
  align-items: center;
}
.projectslisting__filterstoggle span {
  text-decoration: underline;
}
.projectslisting__filterstoggle i {
  margin-left: 10px;
  font-size: 24px;
}
.projectslisting__projects .projectslisting__filterstoggle {
  margin-top: 5px;
}
@media (max-width: 1080px) {
  .projectslisting__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (max-width: 840px) {
  .projectslisting__inner {
    display: block;
  }
  .projectslisting__filterstoggle {
    display: flex;
  }
  .projectslisting__filters {
    display: none;
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-blue-light);
    z-index: 1;
  }
  .projectslisting__filters--visible {
    display: block;
  }
  .projectslisting__filterform__wrapper {
    padding: 20px;
    margin-top: 0;
    max-height: 100%;
    overflow-y: auto;
  }
  .projectslisting__filterform {
    margin-top: 20px;
  }
  .projectslisting__projects {
    max-width: 100%;
  }
  .projectslisting__projects h2 {
    display: none;
  }
  .projectslisting__projects__status {
    margin-top: 25px;
  }
  .projectslisting__projects__status h3 {
    margin-bottom: 10px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
  .projectslisting__item {
    flex: none;
    max-width: none;
    width: 275px;
    padding: 10px;
  }
  .projectslisting__listwrapper {
    margin-left: -30px;
    margin-right: -30px;
    overflow-x: auto;
  }
  .projectslisting__list {
    flex-wrap: nowrap;
    margin-left: 0;
    padding: 10px;
  }
}

.projectpage__header {
  background-color: var(--color-blue-lighter);
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
}
.projectpage__header--spacebottom {
  margin-bottom: 100px;
}
.projectpage__header h1 {
  font-size: 36px;
}
.projectpage__header__image {
  border-radius: 8px 8px 0 0;
  height: 590px;
  max-height: 40vw;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .projectpage__header__image {
    max-height: 320px;
  }
}
.projectpage__header__image::before {
  border-radius: 8px 8px 0 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0 0%, rgba(0, 0, 0, 0.3) 100%);
}
.projectpage__header__image .structuredbreadcrumb {
  color: #fff;
  position: relative;
  margin: 0 30px;
}
@media (max-width: 600px) {
  .projectpage__header__image .structuredbreadcrumb {
    margin: 0 20px;
  }
}
.projectpage__header__image .structuredbreadcrumb li > span {
  opacity: 1;
}
.projectpage__header__details {
  position: relative;
  z-index: 1;
}
.projectpage__header__details__inner {
  display: inline-block;
  width: 470px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: var(--content-padding);
  margin-bottom: -50px;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  position: relative;
  padding-bottom: 80px;
}
.projectpage__header__details__inner h1 {
  max-width: 100%;
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.projectpage__header__details dl {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 25px 0 0;
}
.projectpage__header__details dl dt, .projectpage__header__details dl dd {
  padding: 0;
  margin: 0 0 5px;
  flex: none;
}
.projectpage__header__details dl dt {
  width: 105px;
  opacity: 0.7;
}
.projectpage__header__details dl dd {
  font-weight: 700;
  width: calc(100% - 105px);
}
.projectpage__header__category {
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  padding: 10px 25px;
  border-radius: 8px 0 8px 0;
  font-weight: 700;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.projectpage__header__facts {
  padding: 120px 30px 100px;
  position: relative;
  font-size: 16px;
}
.projectpage__header__facts__cols {
  display: flex;
  justify-content: space-between;
}
.projectpage__header__facts__col {
  flex: 1 1 33.3333%;
  max-width: 270px;
}
.projectpage__header__facts__col__header {
  display: flex;
  align-items: center;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 1380px) {
  .projectpage__header__facts__col__header {
    font-size: 3.3333333333vw;
  }
}
@media (max-width: 900px) {
  .projectpage__header__facts__col__header {
    font-size: 30px;
  }
}
.projectpage__header__facts__col svg {
  margin-right: 20px;
  flex: none;
  width: 40px;
  height: auto;
  fill: var(--color-orange);
}
@media (max-width: 850px) {
  .projectpage__header__facts {
    padding: 110px 30px 40px;
  }
  .projectpage__header__facts__cols {
    display: block;
  }
  .projectpage__header__facts__col {
    display: flex;
    max-width: none;
    align-items: center;
    margin-bottom: 30px;
  }
  .projectpage__header__facts__col__header {
    flex: none;
    width: 180px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .projectpage__header__facts__col__content {
    flex: 1 1 auto;
  }
}
@media (max-width: 500px) {
  .projectpage__header__facts {
    padding: 110px 20px 40px;
  }
  .projectpage__header__facts__col {
    display: block;
  }
  .projectpage__header__facts__col__header {
    width: auto;
    justify-content: center;
  }
  .projectpage__header__facts__col__content {
    text-align: center;
    margin-top: 10px;
  }
}

.projectpage__status__title {
  font-size: 22px;
  font-weight: 300;
  color: var(--color-blue);
}
.projectpage__status__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  position: relative;
}
.projectpage__status__list::before {
  content: "";
  position: absolute;
  left: 17px;
  top: 15px;
  width: 0;
  height: 120px;
  border-left: 2px solid var(--color-blue-light);
}
.projectpage__status__item {
  position: relative;
  padding-left: 50px;
  height: 60px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 35px;
  text-decoration: none;
  display: block;
  color: inherit;
}
.projectpage__status__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid var(--color-blue-light);
  background-color: #fff;
}
.projectpage__status__item--disabled {
  pointer-events: none;
  color: var(--color-blue-disabled);
}
.projectpage__status__item--disabled::after {
  opacity: 0.4;
}
.projectpage__status__item--active::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-orange);
}