/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-phototextcols {
  margin-bottom: 50px;
}
.widget-phototextcols__inner {
  display: flex;
}
.widget-phototextcols__col {
  flex: 1 1 50%;
  max-width: 50%;
  position: relative;
}
.widget-phototextcols__col--image::before {
  content: "";
  display: block;
  padding-top: 75%;
}
.widget-phototextcols__col--text {
  display: flex;
  align-items: center;
}
.widget-phototextcols__col a {
  color: inherit;
}
.widget-phototextcols--orange .widget-phototextcols__col {
  color: #fff;
  background-color: var(--color-orange);
}
.widget-phototextcols--orange .widget-phototextcols__col p {
  color: inherit;
}
.widget-phototextcols--blue .widget-phototextcols__col {
  color: #fff;
  background-color: var(--color-blue);
}
.widget-phototextcols--blue .widget-phototextcols__col p {
  color: inherit;
}
.widget-phototextcols--green .widget-phototextcols__col {
  color: #fff;
  background-color: var(--color-green);
}
.widget-phototextcols--green .widget-phototextcols__col p {
  color: inherit;
}
.widget-phototextcols__content {
  padding: 100px;
}
@media (max-width: 1380px) {
  .widget-phototextcols__content {
    padding: 7.2vw;
  }
}
.widget-phototextcols__content > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 900px) {
  .widget-phototextcols__content {
    padding-left: 0;
    padding-right: 30px;
  }
  .widget-phototextcols--withbgcolor .widget-phototextcols__content {
    padding-left: 30px;
  }
}
.widget-phototextcols.centercontent-wide .widget-phototextcols__content {
  padding: 50px 70px;
}
.widget-phototextcols__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 900px) {
  .widget-phototextcols__col + .widget-phototextcols__col .widget-phototextcols__content {
    padding-left: 30px;
    padding-right: 0;
  }
  .widget-phototextcols--withbgcolor .widget-phototextcols__col + .widget-phototextcols__col .widget-phototextcols__content {
    padding-right: 30px;
  }
}
.widget-phototextcols__col:first-child, .widget-phototextcols__col:first-child .widget-phototextcols__image {
  border-radius: 8px 0 0 8px;
}
.widget-phototextcols__col + .widget-phototextcols__col, .widget-phototextcols__col + .widget-phototextcols__col .widget-phototextcols__image {
  border-radius: 0 8px 8px 0;
}
.widget-phototextcols:not(.widget-phototextcols--withbgcolor) .widget-phototextcols__image {
  border-radius: 8px !important;
}
.widget-phototextcols.centercontent-wide .widget-phototextcols__image {
  border-radius: 8px;
}
@media (max-width: 600px) {
  .widget-phototextcols__inner {
    flex-flow: column;
  }
  .widget-phototextcols__col {
    flex-basis: 100%;
    max-width: 100%;
  }
  .widget-phototextcols__col--image {
    order: -1;
  }
  .widget-phototextcols--withbgcolor .widget-phototextcols__col--image, .widget-phototextcols--withbgcolor .widget-phototextcols__image {
    border-radius: 8px 8px 0 0 !important;
  }
  .widget-phototextcols:not(.widget-phototextcols--withbgcolor) .widget-phototextcols__content {
    padding: 30px 0 0 0 !important;
  }
  .widget-phototextcols--withbgcolor .widget-phototextcols__col--text {
    border-radius: 0 0 8px 8px;
  }
}