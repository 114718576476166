/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-stories {
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
}
.widget-stories__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}
html.mobilenav .widget-stories__list {
  margin-left: -20px;
}
.widget-stories__item {
  margin: 30px 0 0 30px;
  min-height: 350px;
}
html.mobilenav .widget-stories__item {
  margin: 20px 0 0 20px;
}
.widget-stories__item__link {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  color: #fff;
  text-decoration: none;
}
.widget-stories__item__text {
  padding: var(--content-padding);
  font-size: 16px;
  line-height: 165%;
  max-width: 50%;
  position: relative;
  z-index: 1;
}
.widget-stories__item__title {
  font-size: 36px;
  line-height: 105%;
  font-weight: 700;
}
@media (max-width: 1380px) {
  .widget-stories__item__title {
    font-size: 2.6086956522vw;
  }
}
@media (max-width: 843.3333333333px) {
  .widget-stories__item__title {
    font-size: 22px;
  }
}
.widget-stories__item__title + .widget-stories__item__description {
  margin-top: 20px;
}
.widget-stories__item__image {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  flex: none;
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.widget-stories__item__image img {
  width: auto;
  height: 100%;
  vertical-align: bottom;
}
.widget-stories__item--square .widget-stories__item__image {
  width: 100%;
  display: block;
  overflow: hidden;
}
.widget-stories__item--square .widget-stories__item__image img {
  position: absolute;
  top: 0;
  left: -999px;
  right: -999px;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
.widget-stories__item__label {
  font-size: 17px;
  font-weight: 700;
  border-radius: 8px 0 0 0;
  padding: 10px 25px;
  background-color: var(--color-orange);
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (max-width: 650px) {
  .widget-stories__item__label {
    font-size: 15px;
  }
}
.widget-stories__item--square {
  flex: none;
  width: 378px;
}
.widget-stories__item--wide {
  flex: none;
  width: calc(100% - 438px);
}
.widget-stories__item--wide .widget-stories__item__link {
  background-color: var(--color-orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-stories__item--square .widget-stories__item__link::after {
  content: "";
  display: block;
  padding-top: 100%;
}
@media (max-width: 1150px) {
  .widget-stories__item--wide {
    width: calc(100% - 30px);
  }
  html.mobilenav .widget-stories__item--wide {
    width: calc(100% - 20px);
  }
  .widget-stories__item--square {
    width: calc(50% - 30px);
    min-height: 0;
  }
  .widget-stories__item--square__image {
    padding-top: 100%;
  }
  html.mobilenav .widget-stories__item--square {
    width: calc(50% - 20px);
  }
}
@media (max-width: 550px) {
  .widget-stories__item {
    min-height: calc(50vw - 20px);
  }
  .widget-stories__item__link::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .widget-stories__item__text {
    display: none;
  }
  .widget-stories__item--wide {
    width: calc(50% - 20px) !important;
    min-height: 0;
  }
  .widget-stories__item--wide .widget-stories__image {
    width: 100%;
    display: block;
    overflow: hidden;
    padding-top: 100%;
  }
  .widget-stories__item--wide .widget-stories__image img {
    position: absolute;
    top: 0;
    left: -999px;
    right: -999px;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
}
@media (max-width: 450px) {
  .widget-stories__item {
    width: calc(100% - 20px) !important;
  }
}