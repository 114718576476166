.wh-form__buttongroup {
  text-align: right;
  padding: 30px 0;
  margin-top: 30px;
  border-top: 1px solid #D2CFE6;
}
.wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form__buttongroup .wh-form__button {
  margin-bottom: 10px;
}
.wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}

.button, .wh-form__button {
  outline: none;
  -webkit-appearance: none;
  position: relative;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-decoration: none;
  font: 700 15px/24px "Hanken Grotesk", "sans-serif", Arial;
  min-width: 60px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 50px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 0 15px;
  position: relative;
  background-color: var(--color-orange);
  color: #fff;
  text-align: left;
  transition: opacity 0.2s;
  transition: background-color 0.3s;
  box-shadow: none;
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
}
.button[disabled], .wh-form__button[disabled] {
  pointer-events: none;
}
.button:hover, .wh-form__button:hover {
  background-color: var(--color-orange-hover);
}
.button__icon, .wh-form__button__icon {
  display: inline-flex;
  flex: none;
  align-items: center;
  height: 100%;
  font-size: 20px;
}
.button__icon--hasseparator, .wh-form__button__icon--hasseparator {
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.button__icon:first-child, .wh-form__button__icon:first-child {
  margin-right: 15px;
}
.buttonlabel, .button__label, .wh-form__buttonlabel, .wh-form__button__label {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.button__label + .button__icon, .buttonlabel + .button__icon, .button__label + .wh-form__button__icon, .wh-form__buttonlabel + .button__icon, .wh-form__button__label + .button__icon, .wh-form__button__label + .wh-form__button__icon {
  margin-left: 15px;
}
@media (max-width: 650px) {
  .button__label + .button__icon, .buttonlabel + .button__icon, .button__label + .wh-form__button__icon, .wh-form__buttonlabel + .button__icon, .wh-form__button__label + .button__icon, .wh-form__button__label + .wh-form__button__icon {
    margin-left: 10px;
  }
}
.button--uc .button__label, .button--uc .wh-form__button__label, .wh-form__button--uc .button__label, .wh-form__button--uc .wh-form__button__label {
  text-transform: uppercase;
}
.button--small, .wh-form__button--small {
  height: 40px;
  font-size: 16px;
}
.button--large, .wh-form__button--large {
  height: 60px;
  font-size: 18px;
  padding: 0 20px;
}
.button--large:hover, .wh-form__button--large:hover {
  background-color: var(--color-orange-hover);
}
.button--large .button__icon, .button--large .wh-form__button__icon, .wh-form__button--large .button__icon, .wh-form__button--large .wh-form__button__icon {
  font-size: 26px;
}
.button--large .button__icon:first-child, .button--large .wh-form__button__icon:first-child, .wh-form__button--large .button__icon:first-child, .wh-form__button--large .wh-form__button__icon:first-child {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.button--large .button__icon:last-child, .button--large .wh-form__button__icon:last-child, .wh-form__button--large .button__icon:last-child, .wh-form__button--large .wh-form__button__icon:last-child {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 650px) {
  .button, .button--large, .wh-form__button, .wh-form__button--large {
    height: 45px;
    font-size: 13px;
    padding: 0 10px 0 15px;
  }
}
@media (max-width: 650px) {
  .button .button--icon, .button--large .button__icon, .wh-form__button .button--icon, .wh-form__button--large .button__icon {
    font-size: 20px;
  }
  .button .button--icon:first-child, .button--large .button__icon:first-child, .wh-form__button .button--icon:first-child, .wh-form__button--large .button__icon:first-child {
    margin-right: 15px;
    padding-right: 15px;
  }
  .button .button--icon:last-child, .button--large .button__icon:last-child, .wh-form__button .button--icon:last-child, .wh-form__button--large .button__icon:last-child {
    margin-left: 10px;
    padding-left: 10px;
  }
}

.button--animated .wh-form__buttonlabel, .button--animated .button__label, .button--animated .button__icon {
  transition: transform 300ms, opacity 150ms;
}
.button--animated .button__icon--onhover {
  position: absolute;
  left: -10px;
  opacity: 0;
}
.button--animated .wh-form__buttonlabel + .button__icon--onhover, .button--animated .button__label + .button__icon--onhover {
  left: auto;
  right: -10px;
}
.button--animated:hover .wh-form__buttonlabel, .button--animated:hover .button__label, .button--animated:hover .button__icon {
  transform: translateX(25px);
}
.button--animated:hover .button__icon--onhover {
  opacity: 1;
}
.button--animated:hover .button__icon:not(.button__icon--onhover) {
  opacity: 0;
}
.button--animated.wh-form__button--previous:hover .wh-form__buttonlabel, .button--animated.button--previous:hover .button__label, .button--animated.wh-form__button--previous:hover .button__icon, .button--animated.button--previous:hover .button__icon {
  transform: translateX(-25px);
}
@media (max-width: 650px) {
  .button--animated:hover .wh-form__buttonlabel, .button--animated:hover .button__label, .button--animated:hover .button__icon {
    transform: translateX(20px);
  }
  .button--animated.wh-form__button--previous:hover .wh-form__buttonlabel, .button--animated.button--previous:hover .button__label, .button--animated.wh-form__button--previous:hover .button__icon, .button--animated.button--previous:hover .button__icon {
    transform: translateX(-20px);
  }
}

.button--outline {
  background-color: transparent;
  color: var(--color-orange);
  border: 2px solid var(--color-orange);
  transition: background-color 0.2s;
}
.button--outline:hover {
  background-color: #f6f6f6;
}

.button--blue {
  background-color: var(--color-blue);
}

.button--outline.button--blue, .wh-form__button--previous {
  background-color: transparent;
  color: var(--color-blue);
  border-color: rgba(30, 17, 127, 0.15);
  transition: border-color 300ms, background-color 300ms;
}
.button--outline.button--blue:hover, .button--outline.button--blue:focus, .wh-form__button--previous:hover, .wh-form__button--previous:focus {
  border-color: #1e117f;
  background-color: #F8F9FC;
}

.wh-form__button--previous, .button--previous,
.wh-form__button--cancel, .button--cancel {
  float: left;
}

.wh-form__button--cancel, .button--cancel {
  float: none;
  vertical-align: bottom;
  min-width: 80px;
}