:root, .wh-rtd-editor
{
  --color-black: #333;
  --color-white: #fff;
  --color-orange: #FF8C00;
  --color-orange-hover: #FC6203;
  --color-orange-dark: #F06C00;
  --color-blue: #1E117F;
  --color-blue-intro: #5C54A3;
  --color-blue-normal: #463E85;
  --color-blue-dark: #0B005F;
  --color-blue-disabled: rgba(30,17,127,0.4);
  --color-blue-line: #D2CFE6;
  --color-blue-light: #EAEDF7;
  --color-blue-lighter: #F1F3FA;
  --color-green: #98BA02;

 /* forms  */
  --color-red: #D8020A;
  --color-grey-placeholder: #737373;

/* sec colors */
  --sec-color-blue-green: #07989D;
  --sec-color-blue-light: #009BDD;
  --sec-color-purple: #940184;
  --sec-color-red: #D8020A;
  --sec-color-magenta: #E50075;
  --sec-color-green-cyan: #8CC9AA;
  --sec-color-brown-dark: #8E4000;
  --sec-color-brown-light: #C09542;
  --sec-color-yellow: #FFD300;
  --sec-color-pistachio: #98BA02;
  --sec-color-green: #61AF1F;
  --sec-color-grey-dark: #68676C;
}

:root
{
  --grid-gap: 30px;
  --content-padding: 30px;
  --content-padding-mobile: 20px;

  --content-xwide: 1380px;
  --content-wide:  1180px;
  --content-medium: 980px;
  --content-small:  780px;
  --content-xsmall: 580px;
}
