@font-face {
  font-family: 'wp-extraicons-24px';
  src:
    url('fonts/wp-extraicons-24px.ttf?n57rls') format('truetype'),
    url('fonts/wp-extraicons-24px.woff?n57rls') format('woff'),
    url('fonts/wp-extraicons-24px.svg?n57rls#wp-extraicons-24px') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wpextraicon-"], [class*=" wpextraicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wp-extraicons-24px' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wpextraicon-archief:before {
  content: "\e900";
}
.wpextraicon-brochure:before {
  content: "\e901";
}
.wpextraicon-document:before {
  content: "\e902";
}
.wpextraicon-duurzaam:before {
  content: "\e903";
}
.wpextraicon-energie:before {
  content: "\e904";
}
.wpextraicon-euro:before {
  content: "\e905";
}
.wpextraicon-huurachterstand:before {
  content: "\e906";
}
.wpextraicon-infohuren:before {
  content: "\e907";
}
.wpextraicon-kantoor:before {
  content: "\e908";
}
.wpextraicon-opleiding:before {
  content: "\e909";
}
.wpextraicon-password:before {
  content: "\e90a";
}
.wpextraicon-reparatie:before {
  content: "\e90b";
}
.wpextraicon-sign:before {
  content: "\e90c";
}
.wpextraicon-woonwinkel:before {
  content: "\e90d";
}
