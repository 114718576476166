/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-vacancycontact {
  border-radius: 8px;
  margin-bottom: 50px;
  background-color: var(--color-blue-lighter);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
.widget-vacancycontact__image {
  flex: none;
  width: 156px;
  margin-right: 30px;
}
.widget-vacancycontact__image img {
  border-radius: 50%;
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
.widget-vacancycontact__content {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
}
.widget-vacancycontact__text {
  flex: 1 1 50%;
}
.widget-vacancycontact__text > *:last-child {
  margin-bottom: 0;
}
.widget-vacancycontact__contact {
  margin-left: 30px;
  flex: 1 1 50%;
}
.widget-vacancycontact__contact__link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: baseline;
  margin: 5px 0;
}
.widget-vacancycontact__contact__link:hover .widget-vacancycontact__contact__label {
  text-decoration: underline;
  text-underline-position: under;
}
.widget-vacancycontact__contact__icon {
  color: var(--color-orange);
  margin-right: 20px;
  font-size: 24px;
}
@media (max-width: 800px) {
  .widget-vacancycontact__content {
    display: block;
  }
  .widget-vacancycontact__contact {
    margin-top: 20px;
    margin-left: 0;
  }
}
@media (max-width: 550px) {
  .widget-vacancycontact {
    flex-flow: column;
  }
  .widget-vacancycontact__content {
    margin-top: 20px;
  }
}