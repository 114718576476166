/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-infoblock {
  margin-bottom: 50px;
}
.widget-infoblock--lightblue {
  background-color: var(--color-blue-light);
  border-radius: 8px;
  padding: var(--content-padding);
}
.widget-infoblock--lightblue .widget-infoblock__inner {
  padding: 50px 0;
}
@media (max-width: 650px) {
  .widget-infoblock--lightblue .widget-infoblock__inner {
    padding: 20px 0;
  }
}
.widget-infoblock .widget-infoblock__title {
  text-align: center;
  font-weight: 300;
  font-size: 36px;
}
@media (max-width: 1024px) {
  .widget-infoblock .widget-infoblock__title {
    font-size: 3.515625vw;
  }
}
@media (max-width: 739.5555555556px) {
  .widget-infoblock .widget-infoblock__title {
    font-size: 26px;
  }
}
.widget-infoblock__content {
  text-align: center;
}
.widget-infoblock__content > *:last-child {
  margin-bottom: 0;
}
.widget-infoblock__buttons {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-left: -20px;
}
.widget-infoblock__buttons .button {
  max-width: 50%;
  text-transform: uppercase;
  margin-left: 20px;
}
@media (max-width: 650px) {
  .widget-infoblock__buttons {
    margin-top: 40px;
  }
}
@media (max-width: 450px) {
  .widget-infoblock__buttons {
    flex-flow: column;
    align-items: center;
  }
  .widget-infoblock__buttons .button {
    min-width: 160px;
  }
  .widget-infoblock__buttons .button + .button {
    margin-top: 15px;
  }
}