/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.newsoverview {
  margin-bottom: 50px;
}

.defaultcontentheader--newspage {
  padding-bottom: 200px;
}

.newspage-image {
  margin-top: -200px;
  margin-bottom: 50px;
}
.newspage-image img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.newslist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-left: -30px;
  flex-wrap: wrap;
}
.newslist__item {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding-left: 30px;
  margin-bottom: 30px;
}
.newslist__item__link {
  border-radius: 8px;
  display: block;
  height: 100%;
  color: inherit;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  padding-bottom: 15px;
  box-shadow: 0 6px 17px 0 rgba(15, 9, 53, 0.1);
}
.newslist__item__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 8px 8px 0 0;
}
.newslist__item__title {
  padding: 20px 25px 15px;
  font-weight: 700;
}
.newslist__item__description {
  padding: 0 25px 15px;
  font-size: 15px;
  line-height: 165%;
}
@media (max-width: 950px) {
  .newslist__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (max-width: 650px) {
  .newslist {
    margin-left: -20px;
  }
  .newslist__item {
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .newslist__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}