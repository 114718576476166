/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.dwp-multiimage {
  width: 100%;
}
.dwp-multiimage__imagelist {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: -20px;
}
.dwp-multiimage__imagelist__item {
  margin: 0 0 20px 20px;
  flex: none;
  width: 128px;
  height: 128px;
  position: relative;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-blue);
  background-color: #F1F3FA;
}
.dwp-multiimage__imagelist__item img {
  vertical-align: bottom;
  max-width: 100%;
  max-height: 100px;
  height: auto;
}
.dwp-multiimage__imagelist__item--portrait img {
  height: 100%;
  width: auto;
}
.dwp-multiimage__imagelist__item button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  right: 0;
  top: 0;
  color: var(--color-orange-dark);
  padding: 5px;
}