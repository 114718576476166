/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.wh-form__date[type=date] {
  max-width: 170px;
}

.datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  flex: none;
  padding: 0 10px;
}
.datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.datetime__sep {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.datetime__date {
  border: 0 none;
  padding: 0;
}
.datetime__date .datetime__sep {
  visibility: hidden;
}
.datetime__time .datetime__part {
  background: transparent !important;
  border: 0;
  min-width: 29px;
  width: calc(2ch + 10px);
  text-align: center;
  font: inherit;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  border: 0 none !important;
  padding: 0 !important;
  max-height: 41px;
}
.datetime__part {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.datetime__part::-webkit-outer-spin-button, .datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datetime__day, .datetime__month {
  width: 60px;
}
.datetime__year {
  width: 80px;
  text-align: center;
}
.datetime__msec {
  min-width: 36px;
  width: calc(3ch + 8px);
}
.datetime__controls {
  margin-left: 10px;
  color: var(--color-orange-dark);
  flex: none;
  display: flex;
  align-items: center;
  height: 48px;
  border-left: 1px solid var(--color-blue);
}
.datetime__controls > span {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.datetime__date > .datetime__controls, .datetime__picker {
  display: none;
}
.datetime__reset {
  vertical-align: middle;
}
.datetime__reset::before {
  content: "\ea46";
}