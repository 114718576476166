/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-latestnews {
  padding-bottom: 100px;
}
.widget-latestnews__header {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding-bottom: 50px;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
}
.widget-latestnews__header::before {
  content: "";
  display: block;
  width: 33.333%;
}
.widget-latestnews__header > *, .widget-latestnews__header::before {
  flex: 1 1 33.333%;
  max-width: 33.333%;
}
.widget-latestnews__header h2 {
  text-align: center;
  margin-bottom: 0;
}
.widget-latestnews__header__right {
  text-align: right;
}
.widget-latestnews__scrollwrapper {
  max-width: 100%;
}
.widget-latestnews__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  margin-left: -30px;
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
}
.widget-latestnews__item {
  max-width: 33.3333%;
  flex: 1 1 33.3333%;
  padding-left: var(--grid-gap);
  padding-bottom: var(--grid-gap);
  font-size: 16px;
  line-height: 165%;
  min-width: 270px;
}
.widget-latestnews__item__link {
  text-decoration: none;
  display: block;
  height: 100%;
  color: inherit;
}
.widget-latestnews__item__image {
  margin-bottom: var(--grid-gap);
}
.widget-latestnews__item__image img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-latestnews__item__title {
  font-size: 21px;
  font-weight: 700;
}
.widget-latestnews__item__description {
  margin-top: 20px;
  color: #5C54A3;
}
@media (max-width: 650px) {
  .widget-latestnews {
    margin-left: -20px;
    margin-right: -19.5px;
    position: relative;
    padding-bottom: 140px;
  }
  .widget-latestnews__header {
    padding-bottom: 25px;
    display: block;
    padding-left: var(--content-padding-mobile);
    padding-right: var(--content-padding-mobile);
  }
  .widget-latestnews__header > * {
    max-width: 100%;
  }
  .widget-latestnews__header__right {
    text-align: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
  }
  .widget-latestnews__list {
    padding-left: var(--content-padding-mobile);
    padding-right: var(--content-padding-mobile);
  }
}