/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.centercontent-xwide > .widget-headervideo {
  margin-left: -30px;
  margin-right: -30px;
}
html.mobilenav .centercontent-xwide > .widget-headervideo {
  margin-left: -20px;
  margin-right: -20px;
}

.defaultcontentheader .widget-headervideo {
  max-width: calc(100% + 60px);
  width: auto;
  margin-top: 30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -50px;
}

.widget-headervideo {
  position: relative;
}
.widget-headervideo__container {
  overflow: hidden;
  position: relative;
  min-height: 180px;
}
.widget-headervideo__container::after {
  content: "";
  display: block;
  padding-top: 42.65%;
  width: 100%;
}
.widget-headervideo--skewed .widget-headervideo__container {
  -webkit-clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
  border-radius: 0 0 10px 10px;
}
.widget-headervideo--rounded .widget-headervideo__container {
  border-radius: 8px;
}
.widget-headervideo__text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding: 100px;
  background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0%, rgba(38, 33, 81, 0.65) 100%);
  overflow: hidden;
  color: #fff;
}
.widget-headervideo__text > * {
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  max-width: 365px;
}
.widget-headervideo__text > *:last-child {
  margin-bottom: 0;
}
.widget-headervideo__text p, .widget-headervideo__text h1 {
  color: inherit;
}
.widget-headervideo__text h1 {
  font-size: 42px;
  margin-bottom: 20px;
}
@media (max-width: 1380px) {
  .widget-headervideo__text h1 {
    font-size: 3.0434782609vw;
  }
}
@media (max-width: 690px) {
  .widget-headervideo__text h1 {
    font-size: 21px;
  }
}
.widget-headervideo__videoholder {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-width: 100%;
  display: inline-flex;
  transition: transform 300ms;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}
.widget-headervideo__image {
  z-index: 0;
  flex: none;
  width: 100%;
  position: relative;
  display: block;
  transition: opacity 600ms;
}
.widget-headervideo__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.widget-headervideo__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.widget-headervideo__video .wh-video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  aspect-ratio: 16/9;
}
.widget-headervideo__video--playing + .widget-headervideo__image {
  opacity: 0;
}
@media (max-width: 1000px) {
  .widget-headervideo__text {
    padding: 10vw;
  }
}
@media (max-width: 650px) {
  .widget-headervideo__text {
    padding: 15px 30px;
    background: linear-gradient(0deg, rgba(38, 33, 81, 0.65) 0%, rgba(38, 33, 81, 0) 100%);
    left: 0;
  }
  .widget-headervideo__text p {
    font-size: 15px;
  }
  .widget-headervideo__text > * {
    max-width: 100%;
  }
}