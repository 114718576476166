.wh-details__summary {
  position: relative;
  cursor: pointer;
}
.wh-details__summary:focus {
  outline: 0 none;
}
.wh-details__summary::-webkit-details-marker {
  display: none;
}
.wh-details__summary::marker {
  display: none;
}
.wh-details__container {
  height: 0;
  overflow: hidden;
}
.wh-details__container__inner {
  padding-bottom: 15px;
}
.wh-details__container__inner > *:first-child, .wh-details__container__inner > .wh-anchor:first-child + * {
  margin-top: 0;
}
.wh-details__container__inner > *:last-child {
  margin-bottom: 0;
}
.wh-details[open] > .wh-details__container, .wh-details--open > .wh-details__container {
  height: auto;
}