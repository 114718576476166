/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-vacancyapplyflow {
  margin-bottom: 50px;
}
.widget-vacancyapplyflow__steps {
  background-color: var(--color-blue);
  color: #fff;
  border-radius: 8px;
  padding: 80px 100px 160px;
  position: relative;
}
.widget-vacancyapplyflow__steps p {
  color: inherit;
}
.widget-vacancyapplyflow__steps h2 {
  text-align: center;
  margin-bottom: 50px;
}
.widget-vacancyapplyflow__steps__cols {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.widget-vacancyapplyflow__steps__cols::before {
  position: absolute;
  content: "";
  display: block;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  right: 0;
  left: 125px;
}
.widget-vacancyapplyflow__steps__col {
  position: relative;
  padding: 50px 25px;
  flex: 1 1 33.333%;
  max-width: 33.333%;
}
.widget-vacancyapplyflow__steps__col::after {
  content: "";
  background-color: var(--color-orange);
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -6px;
  left: 25px;
  display: block;
}
@media (max-width: 900px) {
  .widget-vacancyapplyflow__steps {
    padding: 50px 30px 160px 10%;
  }
  .widget-vacancyapplyflow__steps__cols {
    flex-flow: column;
    position: relative;
  }
  .widget-vacancyapplyflow__steps__cols::before {
    border-top: 0 none;
    border-left: 2px solid rgba(255, 255, 255, 0.3);
    right: auto;
    left: 0;
    top: 6px;
    bottom: -10px;
  }
  .widget-vacancyapplyflow__steps__col {
    padding: 0 0 10px 30px;
    flex: none;
    max-width: 100%;
  }
  .widget-vacancyapplyflow__steps__col::after {
    top: 4px;
    left: -6px;
  }
}
.widget-vacancyapplyflow__apply {
  font-size: 17px;
  position: relative;
  margin-top: -150px;
  text-align: center;
  padding: 50px;
  border-radius: 8px;
  background-color: var(--color-blue-lighter);
}
.widget-vacancyapplyflow__apply h3 {
  margin-bottom: 20px;
}