/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.vacancieslist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-left: -30px;
  flex-wrap: wrap;
}
.vacancieslist__item {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding-left: 30px;
  margin-bottom: 30px;
}
.vacancieslist__item__link {
  border-radius: 8px;
  display: block;
  height: 100%;
  color: inherit;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  padding-bottom: 55px;
  box-shadow: 0 6px 17px 0 rgba(15, 9, 53, 0.1);
}
.vacancieslist__item__image {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.vacancieslist__item__image::before {
  display: block;
  padding-top: 56.25%;
  content: "";
}
.vacancieslist__item__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 8px 8px 0 0;
}
.vacancieslist__item__title {
  padding: 20px 25px 15px;
  font-weight: 700;
}
.vacancieslist__item__hours, .vacancieslist__item__education {
  padding: 0 25px;
  display: flex;
  align-items: baseline;
  font-size: 16px;
  margin-bottom: 5px;
}
.vacancieslist__item__hours i, .vacancieslist__item__education i {
  flex: none;
  position: relative;
  top: 2px;
  width: 35px;
  font-size: 20px;
}
.vacancieslist__item__category {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 8px 0 0 0;
}
@media (max-width: 850px) {
  .vacancieslist__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (max-width: 650px) {
  .vacancieslist {
    margin-left: -20px;
  }
  .vacancieslist__item {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .vacancieslist__item__title {
    padding: 20px 20px 15px;
  }
  .vacancieslist__item__hours, .vacancieslist__item__education {
    padding: 0 20px;
  }
  .vacancieslist__item__category {
    padding: 10px 20px;
  }
}
@media (max-width: 550px) {
  .vacancieslist__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.vacancies__header {
  background-color: var(--color-blue-lighter);
}
.vacancies__header__image {
  border-radius: 8px 8px 0 0;
  height: 590px;
  max-height: 40vw;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 376px;
  z-index: 0;
}
.vacancies__header__image::after {
  position: absolute;
  content: "";
  display: block;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 80px;
  background-color: var(--color-blue-lighter);
}
.vacancies__header__image .structuredbreadcrumb {
  color: #fff;
}
.vacancies__header__image .structuredbreadcrumb li > span {
  opacity: 1;
}
.vacancies__header__backgroundvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;
}
.vacancies__header__backgroundvideo::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
}
.vacancies__header__backgroundvideo .wh-video {
  flex: none;
  aspect-ratio: 16/9;
  min-width: 100%;
  min-height: 100%;
  pointer-events: none;
}
.vacancies__header__overlay {
  position: relative;
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
}
.vacancies__header__overlay__image {
  height: 62%;
  margin-top: -50px;
}
.vacancies__header__overlay__image svg, .vacancies__header__overlay__image img {
  max-height: 100%;
  max-width: 50vw;
  width: auto;
}
@media (max-width: 600px) {
  .vacancies__header__overlay__image {
    margin-top: -30px;
  }
}
.vacancies__header__alllink {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.vacancies__header .vacancieslist {
  margin-top: 50px;
}
.vacancies__header__novacancies {
  text-align: center;
}
.vacancies__header__buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.vacancies__header__buttons .button {
  max-width: 50%;
  text-transform: uppercase;
  margin: 0 10px;
}
.vacancies__header__content {
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 50px;
}
.vacancies__header__content h2, .vacancies__header__content h1 {
  font-size: 26px;
  text-align: center;
}
.vacancies__header__content__inner {
  position: relative;
  top: -80px;
  border-radius: 8px 0 0 0;
  min-height: 80px;
  padding: 50px var(--content-padding) 0;
  background-color: var(--color-blue-lighter);
}
html.mobilenav .vacancies__header__content__inner {
  padding: 50px var(--content-padding-mobile) 0;
}

.vacancypage__header {
  display: flex;
  color: #fff;
  background-color: var(--color-blue);
  border-radius: 8px 8px 0 0;
  min-height: 527px;
}
@media (max-width: 1440px) {
  .vacancypage__header {
    min-height: 37vw;
  }
}
.vacancypage__header .structuredbreadcrumb {
  padding-top: 0;
  padding-bottom: 30px;
  flex: none;
}
.vacancypage__header__suptitle {
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 700;
}
.vacancypage__header__details {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 30px 30px 130px;
  display: flex;
  justify-content: flex-end;
}
.vacancypage__header__details .button {
  margin-top: 30px;
  align-self: flex-start;
}
.vacancypage__header__content {
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-flow: column;
}
.vacancypage__header__text {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.vacancypage__header__meta {
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -30px;
}
.vacancypage__header__meta__item {
  margin-left: 30px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
.vacancypage__header__meta i {
  flex: none;
  position: relative;
  top: 2px;
  display: inline-block;
  padding-right: 15px;
  font-size: 20px;
}
.vacancypage__header__image {
  flex: 1 1 50%;
  max-width: 50%;
  border-radius: 0 8px 0 0;
}
@media (max-width: 900px) {
  .vacancypage__header {
    display: block;
  }
  .vacancypage__header__image {
    display: none;
  }
  .vacancypage__header__details {
    max-width: 100%;
    display: block;
  }
  .vacancypage__header__content {
    max-width: 100%;
  }
}
.vacancypage__intro {
  background-color: var(--color-blue-lighter);
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
  padding-bottom: 2%;
}
.vacancypage__intro > * {
  max-width: 950px;
}
.vacancypage__intro::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -80px;
  width: 50%;
  height: 100%;
  background-color: var(--color-blue-lighter);
}
.vacancypage__intro::after {
  content: "";
  display: block;
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 100%;
  padding-top: 4%;
  background-color: #fff;
  -webkit-clip-path: polygon(0 100%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 100%, 100% 0%, 100% 100%, 0% 100%);
}
.vacancypage__intro__inner {
  position: relative;
  z-index: 4;
  top: -80px;
  border-radius: 8px 0 0 0;
  background-color: var(--color-blue-lighter);
  padding: 50px 40px 0;
  min-height: 100px;
}
@media (max-width: 1000px) {
  .vacancypage__intro__inner {
    padding: 40px 4vw 0;
  }
}
@media (max-width: 500px) {
  .vacancypage__intro__inner {
    padding: 30px 20px 0;
  }
}
.vacancypage__profile_offer {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 25px;
}
.vacancypage__profile_offer__block {
  background-color: #fff;
  box-shadow: 0 6px 17px 0 rgba(57, 44, 153, 0.08);
  padding: var(--content-padding);
  border-radius: 8px;
  flex: 1 1 50%;
  max-width: calc(50% - 25px);
}
@media (max-width: 1000px) {
  .vacancypage__profile_offer__block {
    max-width: 47.2%;
  }
}
.vacancypage__profile_offer__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.vacancypage__profile_offer__item {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
}
.vacancypage__profile_offer__item__icon {
  flex: none;
  width: 24px;
  margin-right: 20px;
  position: relative;
  top: -5px;
}
.vacancypage__profile_offer__item__icon svg {
  fill: var(--color-orange);
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.vacancypage__profile_offer__item__title {
  flex: 1 1 auto;
}
@media (max-width: 750px) {
  .vacancypage__profile_offer {
    display: block;
  }
  .vacancypage__profile_offer__block {
    max-width: 100%;
    padding: var(--content-padding-mobile);
  }
  .vacancypage__profile_offer__block + .vacancypage__profile_offer__block {
    margin-top: 30px;
  }
}