/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-ctaslides {
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
}
.widget-ctaslides h2 {
  text-align: center;
}
.widget-ctaslides__buttons {
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
@media (max-width: 1380px) {
  .widget-ctaslides__buttons {
    right: -25px;
  }
}
@media (max-width: 900px) {
  .widget-ctaslides__buttons {
    top: 30vw;
    right: 3px;
    z-index: 2;
  }
}
@media (max-width: 650px) {
  .widget-ctaslides__buttons {
    right: 5px;
  }
}
@media (max-width: 600px) {
  .widget-ctaslides__buttons {
    position: relative;
    right: auto;
    top: 0;
    margin-top: 10px;
    flex-flow: row;
  }
}
.widget-ctaslides__jumpbutton {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  background-color: #F0F2F9;
  width: 12px;
  height: 12px;
  flex: none;
  margin: 6px;
  border-radius: 50%;
  transition: background-color 300ms;
}
.widget-ctaslides__jumpbutton--active, .widget-ctaslides__jumpbutton:hover, .widget-ctaslides__jumpbutton:focus {
  background-color: var(--color-orange);
}
.widget-ctaslides__slides {
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  max-width: var(--content-wide);
}
.widget-ctaslides__slide {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  opacity: 0;
  transition: opacity 2s;
  flex: none;
  width: 100%;
}
.widget-ctaslides__slide--active {
  opacity: 1;
  z-index: 1;
}
.widget-ctaslides__slide img {
  border-radius: 8px;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-ctaslides__slide__image {
  flex: none;
  width: 75%;
}
.widget-ctaslides__slide__textcontainer {
  flex: none;
  width: 25%;
  position: relative;
}
.widget-ctaslides__slide__textinner {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 413px;
  padding: 30px 0 60px 30px;
  background-color: #fff;
  border-radius: 16px 0 0;
  color: #fff;
}
@media (max-width: 1000px) {
  .widget-ctaslides__slide__textinner {
    padding-bottom: 6vw;
  }
}
.widget-ctaslides__slide__wrapper {
  border-radius: 8px;
  padding: 30px 35px;
  background-color: var(--color-orange);
  font-size: 19px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 250px;
}
.widget-ctaslides__slide__title {
  font-weight: 700;
  line-height: 110%;
  font-size: 42px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media (max-width: 1380px) {
  .widget-ctaslides__slide__title {
    font-size: 3.0434782609vw;
  }
}
@media (max-width: 1051.4285714286px) {
  .widget-ctaslides__slide__title {
    font-size: 32px;
  }
}
.widget-ctaslides__slide__icon {
  flex: none;
  width: 46px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.widget-ctaslides__slide__icon > svg {
  fill: #fff;
  width: 100%;
  height: auto;
}
@media (max-width: 1380px) {
  .widget-ctaslides__slide__icon {
    width: 3.33vw;
    height: 3.33vw;
    top: -0.15vw;
  }
}
@media (max-width: 1050px) {
  .widget-ctaslides__slide__icon {
    width: 34px;
    height: 34px;
  }
}
.widget-ctaslides__slide__description {
  margin-top: 30px;
  opacity: 0.7;
  font-weight: 600;
}
@media (max-width: 650px) {
  .widget-ctaslides__slide__description {
    font-size: 16px;
    opacity: 0.9;
  }
}
.widget-ctaslides__slide--blue .widget-ctaslides__slide__wrapper {
  background-color: var(--color-blue);
}
.widget-ctaslides__slide--green .widget-ctaslides__slide__wrapper {
  background-color: var(--color-green);
}
@media (max-width: 900px) {
  .widget-ctaslides__slide {
    flex-flow: column;
    justify-content: flex-start;
  }
  .widget-ctaslides__slide__image {
    width: 100%;
  }
  .widget-ctaslides__slide__textcontainer {
    width: calc(100% - 30px);
    height: auto;
    right: auto;
    margin-top: -50px;
    padding-bottom: 0;
  }
  .widget-ctaslides__slide__textinner {
    position: relative;
    width: 100%;
    padding: 20px 50px 10px 20px;
  }
}
@media (max-width: 650px) {
  .widget-ctaslides__slide__textcontainer {
    margin-top: -40px;
  }
  .widget-ctaslides__slide__textinner {
    padding: 15px 45px 10px 15px;
  }
}
@media (max-width: 600px) {
  .widget-ctaslides__slide__textcontainer {
    width: 100%;
    margin-top: -10px;
  }
  .widget-ctaslides__slide__textinner {
    padding: 0;
  }
  .widget-ctaslides__slide__wrapper {
    border-radius: 0 0 8px 8px;
    min-height: 41vw;
  }
}
.widget-ctaslides__slide + .widget-ctaslides__slide {
  margin-left: -100%;
}