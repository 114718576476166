/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-vacancyusps {
  margin-bottom: 50px;
}
.widget-vacancyusps__cols {
  display: flex;
}
.widget-vacancyusps__description {
  flex: 1 1 60%;
  max-width: 60%;
  padding-right: var(--content-padding);
}
.widget-vacancyusps__description > *:last-child {
  margin-bottom: 0;
}
.widget-vacancyusps__usps {
  flex: 1 1 40%;
  max-width: 40%;
  padding-left: var(--content-padding);
}
.widget-vacancyusps__usps__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-vacancyusps__usps__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 16px;
}
.widget-vacancyusps__usps__item__icon {
  flex: none;
  width: 32px;
  margin-right: 20px;
}
.widget-vacancyusps__usps__item__icon svg {
  fill: var(--color-orange);
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-vacancyusps__usps__item + .widget-vacancyusps__usps__item {
  margin-top: var(--content-padding);
}
@media (max-width: 750px) {
  .widget-vacancyusps__cols {
    display: block;
  }
  .widget-vacancyusps__description {
    max-width: 100%;
    padding-right: 0;
  }
  .widget-vacancyusps__usps {
    margin-top: 30px;
    max-width: 100%;
    padding-left: 0;
  }
}