/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.stories__header {
  border-radius: 8px 8px 0 0;
  height: 590px;
  max-height: 40vw;
}

.storypage__header {
  display: flex;
  color: #fff;
  position: relative;
  min-height: 527px;
}
@media (max-width: 1440px) {
  .storypage__header {
    min-height: 37vw;
  }
}
.storypage__header::after {
  position: absolute;
  content: "";
  display: block;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 80px;
  background-color: #fff;
}
.storypage__header__mask {
  background-color: #fff;
  border-radius: 8px 0 0 0;
  margin-top: -80px;
  height: 80px;
  position: relative;
  margin-bottom: 10px;
}
.storypage__header .structuredbreadcrumb {
  padding-top: 0;
  padding-bottom: 30px;
  flex: none;
}
.storypage__header p {
  color: inherit;
}
.storypage__header h1 + * {
  margin-top: 20px;
}
.storypage__header__details {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 30px 30px 130px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-blue);
  border-radius: 8px 0 0 0;
}
.storypage__header__content {
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-flow: column;
}
.storypage__header__content > *:last-child {
  margin-bottom: 0;
}
.storypage__header__text {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.storypage__header__image {
  flex: 1 1 50%;
  max-width: 50%;
  border-radius: 0 8px 0 0;
}
@media (max-width: 850px) {
  .storypage__header {
    display: flex;
    border-radius: 8px;
    min-height: 25vw;
    margin-bottom: 30px;
    flex-flow: column;
  }
  .storypage__header::after, .storypage__header__mask {
    display: none;
  }
  .storypage__header__details {
    flex-basis: 100%;
    max-width: 100%;
    padding: 30px;
    padding-bottom: 20vw;
    border-radius: 8px;
  }
  .storypage__header__content {
    max-width: 100%;
  }
  .storypage__header__image {
    flex: none;
    width: 100%;
    max-width: 100%;
    align-self: center;
    border-radius: 8px;
    margin-top: -20vw;
  }
  .storypage__header__image::after {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}