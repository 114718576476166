/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/* responsive layout overlay */
.layoutoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
.layoutoverlay__columns {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--content-padding);
  outline: 1px dotted rgba(255, 0, 0, 0.3);
  white-space: nowrap;
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
}
.layoutoverlay__columns .col {
  background-color: rgba(0, 0, 0, 0.05);
  outline: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  height: 100vh;
  flex-grow: 1;
}
.layoutoverlay__columns .gap {
  display: inline-block;
  flex: none;
  width: var(--grid-gap);
}