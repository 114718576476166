/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
/* radio / checkbox styling */
.wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}

input[type=radio],
input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
input[type=radio] + label,
input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 6px;
  width: 24px;
  max-width: 24px;
  flex: 0 0 24px;
  height: 24px;
  border: 1px solid #6F67AD;
  background-color: #fff;
  border-radius: 4px;
}
input[type=radio] + label::after,
input[type=checkbox] + label::after {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 22px;
  display: block;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 20px;
  transition: all 0.2s;
}
input[type=radio]:checked + label, input[type=radio]:focus + label, input[type=radio]:hover + label,
input[type=checkbox]:checked + label,
input[type=checkbox]:focus + label,
input[type=checkbox]:hover + label {
  border-color: var(--color-blue);
  border-width: 2px;
}
input[type=radio]:focus + label, input[type=radio]:hover + label,
input[type=checkbox]:focus + label,
input[type=checkbox]:hover + label {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
}
input[type=radio][disabled] + label,
input[type=checkbox][disabled] + label {
  border-color: #F8F9FC;
  cursor: default;
}
input[type=radio][disabled] + label + *,
input[type=checkbox][disabled] + label + * {
  color: #6F67AD;
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 5px;
}

.wh-form__optiondata > * {
  padding-left: 15px;
}

.wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}

.wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 5px;
}

input[type=radio] + label {
  border-radius: 50%;
}
input[type=radio] + label::after {
  border: 4px solid #fff;
  border-radius: 50%;
}
input[type=radio]:checked + label::after {
  background-color: var(--color-blue);
}
input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
input[type=radio][disabled]:checked + label::after {
  background-color: #F8F9FC;
}

input[type=checkbox]:checked + label::after {
  content: "\e9eb";
}
input[type=checkbox]:checked + label {
  border-color: var(--color-blue);
}
input[type=checkbox][disabled] + label::after {
  content: "";
}
input[type=checkbox][disabled]:checked + label::after {
  content: "\e9eb";
  background-color: #F2F2F2;
  color: #F8F9FC;
}

.wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: var(--color-red);
}

.wh-styledinput--nativecheckbox input[type=checkbox] {
  display: block;
  position: relative;
  top: 6px;
  flex: none;
  left: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #6F67AD;
  border-radius: 4px;
}
.wh-styledinput--nativecheckbox input[type=checkbox]::after {
  position: absolute;
  top: 2px;
  left: 3px;
  display: block;
  content: "";
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.wh-styledinput--nativecheckbox input[type=checkbox]:checked {
  background-color: var(--color-blue);
}
.wh-styledinput--nativecheckbox input[type=checkbox]:checked::after {
  color: #fff;
  content: "\e9eb";
}
.wh-styledinput--nativecheckbox input[type=checkbox][disabled]:checked::after {
  color: #aaa;
}
.wh-styledinput--nativecheckbox input[type=checkbox][disabled], .wh-styledinput--nativecheckbox input[type=checkbox][disabled]:checked {
  background-color: #f2f2f2;
}