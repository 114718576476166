.widget-twocol__columns {
  display: flex;
}
.widget-twocol__columns__col {
  flex: 0 1 50%;
  max-width: 50%;
  padding-right: 12px;
}
.widget-twocol__columns__col > *:last-child {
  margin-bottom: 0;
}
.widget-twocol__columns__col + .widget-twocol__columns__col {
  padding-left: 12px;
  padding-right: 0px;
}
@media (max-width: 600px) {
  .widget-twocol__columns {
    display: block;
  }
  .widget-twocol__columns__col {
    max-width: 100%;
    padding-right: 0;
  }
  .widget-twocol__columns__col + .widget-twocol__columns__col {
    padding-top: 20px;
    padding-left: 0;
  }
}