/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
@import "../shared/flags/flags.css";
html.mobilenav body {
  margin-top: 80px;
}

html.mobilenav .header__bottom .header__logo {
  margin-right: 80px;
}
html.mobilenav .header__bottom .mainnav__mydwp {
  position: absolute;
  right: 15px;
}

.header {
  position: relative;
  z-index: 10;
  font-size: 15px;
  padding: 0 var(--content-padding);
}
@media (max-width: 600px) {
  .header {
    padding: 0 var(--content-padding-mobile);
  }
}
.header__top {
  background-color: var(--color-blue-lighter);
  padding: 0 30px;
  height: 56px;
  margin: 0 -30px;
}
.header__top > div {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;
}
html.mobilenav .header__top {
  display: none;
}
.header__bottom {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
html.mobilenav .header__bottom {
  background-color: #fff;
  padding: 0 15px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  right: 0;
}
html.mobilenav.mobilenav--active .header__bottom::before {
  display: block;
  content: "";
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.header__translate {
  flex: none;
  height: 100%;
  position: relative;
  z-index: 1;
}
.header__translate button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font: inherit;
}
.header__translate__header {
  padding-top: 28px;
  height: 20px;
  display: flex;
  align-items: center;
}
.header__translate__select {
  display: flex;
  align-items: center;
}
.header__translate__select span {
  display: inline-block;
  padding-right: 10px;
}
.header__translate__selected {
  margin-left: 10px;
  flex: none;
  width: 24px; /* 4x3 ratio */
  height: 18px;
}
.header__translate__optionlist {
  overflow: hidden;
  position: absolute;
  left: -30px;
  top: 100%;
  height: 0;
  background-color: #fff;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  transition: height 300ms;
}
.header__translate .header__translate__option {
  font-size: 15px;
  color: inherit;
  text-decoration: none;
  text-underline-position: under;
  line-height: 20px;
  display: block;
  padding: 10px 30px;
  white-space: nowrap;
}
.header__translate .header__translate__option:hover {
  text-decoration: underline;
}
.header__translate__option:first-child {
  margin-top: 10px;
}
.header__translate__option:last-child {
  margin-bottom: 10px;
}
.header__translate--open .header__translate__optionlist, .header__translate:focus-within .header__translate__optionlist, .header__translate:hover .header__translate__optionlist {
  height: 140px;
}

.header__mobilemenutoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: none;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: var(--color-blue-lighter);
  margin: 15px 0;
  font-size: 24px;
  margin-right: 15px;
  transition: background-color 300ms, color 300ms;
}
.header__mobilemenutoggle::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea4d";
}
.header__mobilemenutoggle:hover, .header__mobilemenutoggle:focus {
  background-color: var(--color-blue);
  color: #fff;
}
html.mobilenav .header__mobilemenutoggle {
  display: block;
  flex: none;
}
html.mobilenav.mobilenav--active .header__mobilemenutoggle::before {
  content: "\ea4b";
}

.header__logo {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 234px;
  flex: none;
}
.header__logo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
html.mobilenav .header__logo {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 148px;
}

.mainnav {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.mainnav-wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.mainnav a {
  color: inherit;
  border-bottom: 2px solid transparent;
}
.mainnav__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}
.mainnav__item {
  position: relative;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.mainnav__item__arrow {
  display: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  top: 9px;
  left: 0;
  font-size: 14px;
  padding: 5px 10px;
}
.mainnav__item__arrow::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e978";
}
.mainnav__item__link {
  padding: 9px 0 7px;
  white-space: nowrap;
  display: block;
  font-weight: 500;
}
.mainnav__item__link:hover, .mainnav__item--active .mainnav__item__link {
  border-bottom: 2px solid var(--color-orange);
}
.mainnav a {
  text-decoration: none;
}
.mainnav__foldout {
  position: absolute;
  top: 100%;
  left: -35px;
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  width: 288px;
  transition: max-height 300ms, opacity 300ms;
  opacity: 0;
}
.mainnav__foldout[hidden] {
  display: none;
}
html.mobilenav .mainnav__foldout--mobileonly[hidden] {
  display: block;
}
.mainnav__item:hover > .mainnav__foldout, .mainnav__item:focus > .mainnav__foldout, .mainnav__item:focus-within > .mainnav__foldout {
  max-height: 100vh;
  z-index: 1;
  opacity: 1;
}
.mainnav__sublist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 20px 30px;
}
.mainnav__subitem {
  margin: 5px 0;
  position: relative;
}
.mainnav__subitem__arrow {
  display: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  top: 3px;
  left: 20px;
  font-size: 14px;
  padding: 5px 10px;
}
.mainnav__subitem__arrow::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e978";
}
.mainnav__subitem__link {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-weight: 600;
  padding: 9px 15px 5px;
  border-radius: 4px;
}
.mainnav__subitem__link::after {
  flex: none;
  font-size: 12px;
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  content: "\e978";
}
.mainnav__subitem__link:hover, .mainnav__subitem__link:focus {
  background-color: var(--color-blue-lighter);
}
.mainnav__mydwp, .mainnav__mobilebottom__mydwp {
  margin-left: 15px;
  align-self: center;
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  flex: none;
  display: flex;
  align-items: center;
  line-height: 20px;
  background-color: var(--color-blue);
  height: 50px;
  font-weight: 600;
  border-radius: 4px;
  padding-right: 15px;
}
.mainnav__mydwp__label, .mainnav__mobilebottom__mydwp__label {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainnav__mydwp::before, .mainnav__mobilebottom__mydwp::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 15px;
  content: "\ea08";
  background-color: var(--color-orange);
  flex: none;
  display: inline-flex;
  font-size: 26px;
  align-items: center;
  border-radius: 4px 0 0 4px;
  justify-content: center;
  height: 100%;
  width: 50px;
}
html.compactheader .mainnav__mydwp {
  padding-right: 0;
  background-color: transparent;
}
html.compactheader .mainnav__mydwp::before {
  margin-right: 0;
  border-radius: 4px;
  color: var(--color-blue);
  background-color: var(--color-blue-light);
}
html.compactheader .mainnav__mydwp__label {
  display: none;
}
html.mobilenav .mainnav__mydwp::before {
  background-color: transparent;
  transition: background-color 300ms, color 300ms;
}
html.mobilenav .mainnav__mydwp:hover::before, html.mobilenav .mainnav__mydwp:focus::before {
  background-color: var(--color-blue);
  color: #fff;
}
.mainnav__mobilebottom {
  display: none;
  margin-right: 15px;
  margin-top: 30px;
  width: 250px;
}
.mainnav__mobilebottom__contact {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--color-blue-lighter);
  margin-top: 15px;
  border-radius: 4px;
  margin-left: 15px;
}
.mainnav__mobilebottom__contact a {
  display: flex;
  color: inherit;
  text-decoration: none;
  align-items: center;
  line-height: 20px;
  font-weight: 500;
}
.mainnav__mobilebottom__contact i {
  flex: none;
  display: inline-block;
  margin-right: 10px;
  font-size: 22px;
  color: var(--color-orange);
  transition: color 300ms;
}
.mainnav__mobilebottom__contact a:hover > span {
  text-shadow: 0 0 1px var(--color-blue);
}
.mainnav__mobilebottom__contact a:hover i {
  color: var(--color-orange-hover);
}
html.mobilenav .mainnav {
  display: block;
}
html.mobilenav .mainnav__mobilebottom {
  display: block;
}
html.mobilenav .mainnav-wrapper {
  border-top: 1px solid var(--color-blue-light);
  position: fixed;
  flex: none;
  display: block;
  top: 80px;
  bottom: 0;
  left: 0;
  width: 0;
  padding: 15px 0;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  max-width: 100vw;
  overflow: hidden;
  transition: width 300ms;
  overflow: hidden;
  overflow-y: auto;
}
html.mobilenav .mainnav__foldout {
  position: relative;
  top: 0;
  left: 0;
  margin-left: -35px;
  margin-top: -5px;
  box-shadow: none;
  width: auto;
}
html.mobilenav .mainnav__item .mainnav__foldout {
  max-height: 0;
}
html.mobilenav .mainnav__item:focus > .mainnav__foldout, html.mobilenav .mainnav__item:focus-within > .mainnav__foldout, html.mobilenav .mainnav__subitem:focus > .mainnav__foldout, html.mobilenav .mainnav__subitem:focus-within > .mainnav__foldout, html.mobilenav .mainnav__item--active > .mainnav__foldout, html.mobilenav .mainnav__subitem--active > .mainnav__foldout {
  max-height: 100vh;
  z-index: 1;
  opacity: 1;
}
html.mobilenav .mainnav__list {
  display: block;
}
html.mobilenav .mainnav__sublist {
  padding: 0;
}
html.mobilenav .mainnav__subitem {
  margin: 0;
  min-height: 34px;
}
html.mobilenav .mainnav__subitem:focus-within > .mainnav__subitem__arrow, html.mobilenav .mainnav__subitem--active > .mainnav__subitem__arrow {
  transform: rotate(90deg);
}
html.mobilenav .mainnav__subitem__arrow {
  display: inline-block;
}
html.mobilenav .mainnav__subitem__link {
  padding: 3px 10px 3px 55px;
  font-weight: 400;
}
html.mobilenav .mainnav__subitem__link::after {
  display: none;
}
html.mobilenav .mainnav__subitem .mainnav__subitem .mainnav__subitem__link {
  padding-left: 110px;
}
html.mobilenav .mainnav__item {
  padding-left: 35px;
  display: block;
}
html.mobilenav .mainnav__item:focus-within > .mainnav__item__arrow, html.mobilenav .mainnav__item--active > .mainnav__item__arrow {
  transform: rotate(90deg);
}
html.mobilenav .mainnav__item__arrow {
  display: inline-block;
}
html.mobilenav .mainnav__item__link {
  font-weight: 700;
  border: 0 none;
}
html.mobilenav .mainnav__item__link:hover {
  text-decoration: underline;
  text-underline-position: under;
}
html.mobilenav.mobilenav--active .mainnav-wrapper {
  width: 340px;
}

.header__search,
.header__mobilesearch {
  align-self: center;
  flex: none;
}
.header__search input,
.header__mobilesearch input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 40px;
  border: 0 none;
  border-radius: 0;
  background-color: #fff;
  font-size: 20px;
  display: block;
  color: inherit;
  flex: 1;
  margin-left: 10px;
  font: inherit;
  font-weight: 700;
  padding: 0 10px;
}
.header__search button,
.header__mobilesearch button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 26px;
  flex: none;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.header__mobilesearch {
  display: none;
  width: 0;
  height: 50px;
}
.header__mobilesearch__wrapper {
  height: 0;
  overflow: hidden;
  right: 0;
  left: 0;
  opacity: 0;
  top: 100%;
  transition: height 300ms, opacity 300ms;
  position: absolute;
  background-color: var(--color-blue-lighter);
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
}
.header__mobilesearch__form {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__mobilesearch__showsearch {
  position: absolute;
  top: 15px;
  right: 65px;
  transition: opacity 300ms;
}
.header__mobilesearch button {
  transition: background-color 300ms, color 300ms;
}
.header__mobilesearch button i {
  position: relative;
  top: 2px;
}
.header__mobilesearch button:hover, .header__mobilesearch button:focus {
  background-color: var(--color-blue);
  color: #fff;
}
.header__mobilesearch__dosearch {
  margin-left: 10px;
}
html.mobilenav .header__mobilesearch {
  display: flex;
}
html.mobilenav .header__mobilesearch .header__mobilesearch__wrapper:focus-within {
  height: 70px;
  opacity: 1;
  overflow: visible;
}
html.mobilenav .header__mobilesearch .header__mobilesearch__wrapper:focus-within + .header__mobilesearch__showsearch {
  opacity: 0;
  pointer-events: none;
}

.header__search {
  display: flex;
  margin-left: 15px;
  width: 50px;
  height: 50px;
}
.header__search .wh-autocomplete-values {
  left: 10px;
  transform: translateY(-45px);
}
.header__search__wrapper {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  right: 0;
  background-color: var(--color-blue-light);
  border-radius: 4px;
  position: absolute;
  transition: width 300ms;
  text-align: right;
}
.header__search__showsearch {
  display: flex;
}
.header__search__dosearch {
  display: none;
}
.header__search__form:focus .header__search__wrapper, .header__search__form:focus-within .header__search__wrapper {
  width: calc(100% - 10px);
}
.header__search__form:focus::before, .header__search__form:focus-within::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.header__search__form:focus .header__search__showsearch, .header__search__form:focus-within .header__search__showsearch {
  display: none;
}
.header__search__form:focus .header__search__dosearch, .header__search__form:focus-within .header__search__dosearch {
  display: flex;
}
html.mobilenav .header__search {
  display: none;
}