/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

:-ms-input-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

input:-moz-placeholder {
  opacity: 1;
  color: var(--color-grey-placeholder);
}

input:disabled::-webkit-input-placeholder {
  opacity: 0.8;
}

input:disabled::-moz-placeholder {
  opacity: 0.8;
}

input:disabled:-ms-input-placeholder {
  opacity: 0.8;
}

input:disabledinput:-moz-placeholder {
  opacity: 0.8;
}

.wh_form__requirednote {
  border-radius: 9px;
  padding: 15px;
  margin-bottom: 20px;
}
.wh_form__requirednote__icon {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 5px;
  font-size: 20px;
  color: var(--color-orange);
}

.wh-form__fieldgroup .wh-form__button {
  background-color: var(--color-blue);
  font-weight: 400;
}

.wh-form__richtext > *:last-child {
  margin-bottom: 0;
}

.wh-form__fieldgroup--group {
  margin-bottom: 0 !important;
}

/* infotext below input */
.wh-form__infotextwrapper {
  width: 100%;
  outline: none;
}
.wh-form__infotextwrapper p, .wh-form__infotextwrapper h2 {
  margin-bottom: 5px;
}
.wh-form__infotextwrapper .wh-form__infotext {
  font-size: 15px;
  padding: 0 0 10px;
  width: 100%;
}
.wh-form__infotextwrapper .wh-form__infotext > *:last-child {
  margin-bottom: 0;
}

.wh-form__fieldline--nowrap.wh-form__fieldline--hasinfotext {
  flex-wrap: wrap;
}

.wh-form__optiondata + .wh-form__infotextwrapper {
  margin-top: 10px;
  flex: 0 0 100%;
}

.wh-styledinput {
  font-size: inherit;
}
.wh-styledinput .wh-anchor {
  scroll-margin-top: 120px;
}
.wh-styledinput hr {
  display: block;
  border: 0 none;
  border-top: 1px solid var(--color-orange);
  margin: 30px 0 25px;
}
.wh-styledinput h2.wh-form__grouptitle {
  border-bottom: 0 none;
  padding-bottom: 0;
  font-size: 26px;
}
.wh-styledinput .wh-form__fieldgroup {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  position: relative;
}
.wh-styledinput .wh-form__fieldgroup--heading {
  margin-bottom: 0;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  padding-bottom: 3px;
  padding-right: 0;
}
.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  flex: none !important;
  max-width: 100% !important;
  min-width: 0 !important;
}
.wh-styledinput .wh-form__fields {
  width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after {
  display: inline-block;
  content: "*";
}
.wh-styledinput .wh-form__textinput, .wh-styledinput .wh-form__textarea {
  background-color: #F8F9FC;
}
.wh-styledinput .wh-form__textinput:not(:disabled):focus, .wh-styledinput .wh-form__textarea:not(:disabled):focus {
  background-color: #FFFFFF;
}
.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: 16px;
}
.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: 16px;
}
.wh-styledinput .wh-form__optionlabel, .wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: 16px;
  line-height: 140%;
}
.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: none;
  max-width: none;
  font-weight: 700;
  font-size: 16px;
  color: var(--color-blue);
}
.wh-styledinput .wh-form__fieldgroup--textedit, .wh-styledinput .wh-form__fieldgroup--textarea,
.wh-styledinput .wh-form__fieldgroup--pulldown,
.wh-styledinput .wh-form__fieldgroup--upload,
.wh-styledinput .wh-form__fieldgroup--date,
.wh-styledinput .wh-form__fieldgroup--time {
  padding-top: 10px;
}
.wh-styledinput .wh-form__fieldgroup--textedit > .wh-form__label, .wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--pulldown > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--upload > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--date > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--time > .wh-form__label {
  font-weight: 700;
}
.wh-styledinput .wh-form__fieldgroup--textedit.wh-form__fieldgroup--label-in-field > .wh-form__label, .wh-styledinput .wh-form__fieldgroup--textarea.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--pulldown.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--upload.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--date.wh-form__fieldgroup--label-in-field > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--time.wh-form__fieldgroup--label-in-field > .wh-form__label {
  top: 27px;
  font-size: 16px;
  color: var(--color-black);
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label:empty,
.wh-styledinput .wh-form__fieldgroup > .wh-form__label[hidden] {
  display: none;
}
.wh-styledinput .wh-form__fields, .wh-styledinput .wh-form__label {
  font-size: 16px;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-styledinput .wh-form__fieldgroup.labelabove {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
}
.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__label {
  padding-bottom: 10px;
}
.wh-styledinput .wh-form__fieldgroup.labelabove .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__label, .wh-styledinput .wh-form__fieldgroup.labelabove > .wh-form__fields {
  flex: none;
  max-width: calc(100vw - 40px);
  min-width: 0;
}
.wh-styledinput .wh-form__fieldgroup.labelabove .wh-form__fields {
  width: 100%;
}
.wh-styledinput select + .wh-form__subfield > label,
.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea {
  min-height: 48px;
  padding: 14px 15px;
  flex: 1;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea,
.wh-styledinput .wh-form__imgedit,
.wh-styledinput .datetime__time,
.wh-styledinput .wh-form__uploadfieldfilename {
  border: 1px solid #6F67AD;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  max-width: 100%;
  color: inherit;
  border-radius: 4px;
  background-color: #F8F9FC;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-styledinput input:disabled, .wh-styledinput input[data-wh-form-disabled], .wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-styledinput textarea:disabled,
.wh-styledinput textarea[data-wh-form-disabled],
.wh-styledinput .wh-form__imgedit:disabled,
.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-styledinput .datetime__time:disabled,
.wh-styledinput .datetime__time[data-wh-form-disabled],
.wh-styledinput .wh-form__uploadfieldfilename:disabled,
.wh-styledinput .wh-form__uploadfieldfilename[data-wh-form-disabled] {
  color: #666;
  border-color: #F8F9FC;
  background-color: #F8F9FC;
  box-shadow: none;
}
.wh-styledinput input:focus-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus-within,
.wh-styledinput textarea:focus-within,
.wh-styledinput .wh-form__imgedit:focus-within,
.wh-styledinput .datetime__time:focus-within,
.wh-styledinput .wh-form__uploadfieldfilename:focus-within {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
  background-color: #FFFFFF;
}
.wh-styledinput input:focus, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus,
.wh-styledinput textarea:focus,
.wh-styledinput .wh-form__imgedit:focus,
.wh-styledinput .datetime__time:focus,
.wh-styledinput .wh-form__uploadfieldfilename:focus {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
  background-color: #FFFFFF;
}
.wh-styledinput input:hover, .wh-styledinput select:not(.wh-rtd__toolbarstyle):hover,
.wh-styledinput textarea:hover,
.wh-styledinput .wh-form__imgedit:hover,
.wh-styledinput .datetime__time:hover,
.wh-styledinput .wh-form__uploadfieldfilename:hover {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 4px rgba(30, 17, 127, 0.15);
}
.wh-styledinput textarea {
  min-height: 140px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline input, .wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline select,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline textarea,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .wh-form__imgedit,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__fieldline .datetime__time {
  border-color: var(--color-red);
}
.wh-styledinput .wh-form__fieldline--example {
  margin-top: 7px;
}
.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  font-weight: 400;
  width: 100%; /* force error text below item */
  color: var(--color-red);
  padding: 8px 0 0;
  font-size: 14px;
  line-height: 165%;
}
.wh-styledinput .wh-form__error::before {
  content: "\e91f";
  display: inline-block;
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 7px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__error,
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__fields > .wh-form__error {
  display: block;
}