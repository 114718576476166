/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-bgcolortext {
  margin-bottom: 50px;
}
.widget-bgcolortext__inner {
  position: relative;
  padding: 0 var(--content-padding) 30px;
}
.widget-bgcolortext__content {
  margin: 0 auto;
  padding: 50px 0;
  max-width: var(--content-small);
}
.widget-bgcolortext__content > *:last-child {
  margin-bottom: 0;
}

.widget + .widget-bgcolortext {
  margin-top: -50px;
}