/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-projects {
  position: relative;
  padding-bottom: 2%;
  position: relative;
  padding-top: 50px;
  margin-bottom: 50px;
}
@media (max-width: 650px) {
  .widget-projects {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.widget-projects::after {
  background: linear-gradient(180deg, #FFFFFF 0%, #F1F3FA 100%);
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 98%, 0% 100%);
  clip-path: polygon(0 0%, 100% 0%, 100% 98%, 0% 100%);
}
.widget-projects__header {
  text-align: center;
  padding-bottom: 30px;
}
@media (max-width: 650px) {
  .widget-projects__header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget-projects__scrollcontainer {
  transform: translateZ(0);
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-top: 40px;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.widget-projects__scrollcontainer::-webkit-scrollbar {
  display: none;
}
.widget-projects__scrollcontainer.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
.widget-projects__scrollcontainer.scrollx-at-start ~ .widget-projects__button-previous, .widget-projects__scrollcontainer.scrollx-at-end ~ .widget-projects__button-next {
  cursor: default;
  opacity: 0;
  pointer-events: none;
}
.widget-projects__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-flex;
  transition: transform 300ms;
}
.widget-projects__item {
  margin: 20px;
  flex: none;
  width: 300px;
}
.widget-projects__item__link {
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}
.widget-projects__item--focus .widget-projects__item__link, .widget-projects__item:hover .widget-projects__item__link {
  box-shadow: 0 19px 33px 0 rgba(15, 9, 53, 0.22);
}
.widget-projects__item--up {
  transform: translateY(-40px);
}
.widget-projects__item__image img {
  vertical-align: bottom;
}
.widget-projects__item__category {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  font-weight: 700;
  font-size: 17px;
  border-radius: 15px 0 0 0;
  padding: 15px 25px;
  color: #fff;
  background-color: var(--color-orange);
}
.widget-projects__item__category:first-letter {
  text-transform: uppercase;
}
.widget-projects__item__info {
  max-width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s;
  text-align: center;
  margin-top: 40px;
}
.widget-projects__item__info h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
.widget-projects__item__info p {
  font-size: 16px;
  color: #5C54A3;
}
.widget-projects__item--focus .widget-projects__item__info, .widget-projects__item:hover .widget-projects__item__info {
  opacity: 1;
}
.widget-projects__button-previous, .widget-projects__button-next {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  color: #fff;
  background-color: var(--color-blue);
  bottom: 380px;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 80px;
  font-size: 26px;
  transition: opacity 300ms;
}
.widget-projects__button-previous {
  left: -30px;
  border-radius: 0 8px 8px 0;
}
html.mobilenav .widget-projects__button-previous {
  left: -20px;
  width: 45px;
}
@media (max-width: 650px) {
  .widget-projects__button-previous {
    left: 0 !important;
    width: 40px !important;
  }
}
.widget-projects__button-next {
  right: -30px;
  border-radius: 8px 0 0 8px;
}
html.mobilenav .widget-projects__button-next {
  right: -20px;
  width: 45px;
}
@media (max-width: 650px) {
  .widget-projects__button-next {
    right: 0 !important;
    width: 40px !important;
  }
}