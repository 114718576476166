/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.styledscrollbar-x,
.styledscrollbar-y {
  overflow: hidden;
  scroll-behavior: smooth;
  /* firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--color-orange) white;
  /* webkit */
}
.styledscrollbar-x::-webkit-scrollbar,
.styledscrollbar-y::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-track-piece,
.styledscrollbar-y::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-button, .styledscrollbar-x::-webkit-scrollbar-corner, .styledscrollbar-x::-webkit-resizer,
.styledscrollbar-y::-webkit-scrollbar-button,
.styledscrollbar-y::-webkit-scrollbar-corner,
.styledscrollbar-y::-webkit-resizer {
  display: none;
}

.styledscrollbar-x {
  overflow-x: auto;
  /* webkit */
}
.styledscrollbar-x::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 7px 7px var(--color-orange);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}
.styledscrollbar-x::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px 7px #fff;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
}
@media (max-width: 650px) {
  .styledscrollbar-x::-webkit-scrollbar-thumb {
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
  }
  .styledscrollbar-x::-webkit-scrollbar-track {
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
  }
}

.styledscrollbar-y {
  overflow-y: auto;
  /* webkit */
}
.styledscrollbar-y::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 7px 7px var(--color-orange);
  border-top: solid 30px transparent;
  border-bottom: solid 30px transparent;
}
.styledscrollbar-y::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px 7px #fff;
  border-top: solid 30px transparent;
  border-bottom: solid 30px transparent;
}

.maingrid {
  width: 100%;
  max-width: var(--content-xwide);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [xwide-start] 1fr [wide-start] 1fr [medium-start] 1fr [small-start] 1fr [xsmall-start] 580px [xsmall-end] 1fr [small-end] 1fr [medium-end] 1fr [wide-end] 1fr [xwide-end];
}
.maingrid__xsmall, .maingrid--default-xsmall > * {
  grid-column: xsmall;
}
.maingrid--default-small > * {
  grid-column: small;
}
.maingrid--default-wide > * {
  grid-column: wide;
}
.maingrid--default-medium > * {
  grid-column: medium;
}
.maingrid > .maingrid__xwide {
  grid-column: xwide;
}
.maingrid > .maingrid__wide {
  grid-column: wide;
}
.maingrid > .maingrid__medium {
  grid-column: medium;
}
.maingrid > .maingrid__small {
  grid-column: small;
}
.maingrid > .maingrid__leftcolumn {
  grid-area: 1/wide-start/2000/xsmall-start;
}
.maingrid > .maingrid__rightcolumn {
  grid-area: 1/small-end/2000/wide-end;
}
@media (max-width: 640px) {
  .maingrid {
    display: block;
  }
}

.fg-orange {
  color: var(--color-orange);
}

.fg-blue {
  color: var(--color-blue);
}

/* prevent occacional double click from selecting text */
.preventselection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.more-folderlink {
  flex: none;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  color: inherit;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.more-folderlink i {
  margin-left: var(--grid-gap);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  width: 60px;
  height: 60px;
  flex: none;
  border-radius: 8px;
  border: 2px solid rgba(30, 17, 127, 0.2);
  transition: background-color 300ms, color 300ms;
}
.more-folderlink--50 {
  font-size: 16px;
}
.more-folderlink--50 i {
  width: 50px;
  height: 50px;
  margin-left: 20px;
}
.more-folderlink:hover i, .more-folderlink:focus i {
  background-color: var(--color-blue);
  color: #fff;
}
@media (max-width: 650px) {
  .more-folderlink {
    font-size: 14px;
  }
  .more-folderlink i {
    font-size: 18px;
    width: 45px;
    height: 45px;
    margin-left: 20px;
  }
}

.centercontent,
.centercontent-xwide,
.centercontent-wide,
.centercontent-medium,
.centercontent-small,
.centercontent-xsmall {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.centercontent-xwide,
.centercontent-xwide-xwide,
.centercontent-wide-xwide,
.centercontent-medium-xwide,
.centercontent-small-xwide,
.centercontent-xsmall-xwide {
  max-width: var(--content-xwide);
}
.centercontent-wide,
.centercontent-xwide-wide,
.centercontent-wide-wide,
.centercontent-medium-wide,
.centercontent-small-wide,
.centercontent-xsmall-wide {
  max-width: var(--content-wide);
}
.centercontent-medium,
.centercontent-xwide-medium,
.centercontent-wide-medium,
.centercontent-medium-medium,
.centercontent-small-medium,
.centercontent-xsmall-medium {
  max-width: var(--content-medium);
}
.centercontent-small,
.centercontent-xwide-small,
.centercontent-wide-small,
.centercontent-medium-small,
.centercontent-small-small,
.centercontent-xsmall-small {
  max-width: var(--content-small);
}
.centercontent-xsmall,
.centercontent-xwide-xsmall,
.centercontent-wide-xsmall,
.centercontent-medium-xsmall,
.centercontent-small-xsmall,
.centercontent-xsmall-xsmall {
  max-width: var(--content-xsmall);
}

.contentbg-lightgrey {
  background-color: var(--color-blue-lighter);
}

.contentskewed-bottom {
  position: relative;
}
.contentskewed-bottom::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  right: -1px;
  left: 0;
  height: 50px;
  background-color: #fff;
  -webkit-clip-path: polygon(0 100%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 100%, 100% 0%, 100% 0%, 100% 100%);
}
@media (max-width: 1440px) {
  .contentskewed-bottom::after {
    height: 3.5vw;
  }
}

/* loading spinner */
.spinanimation {
  -webkit-animation: spinner-anim 2s infinite linear;
  animation: spinner-anim 2s infinite linear;
}

@-webkit-keyframes spinner-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinner-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}