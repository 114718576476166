/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.wh-form__arrayinput {
  width: 1px;
  padding: 0;
  min-height: 0;
}

.wh-form__fieldgroup--array .wh-form__arrayrow {
  position: relative;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
}

.wh-form__arraydelete {
  align-self: flex-start;
  position: absolute;
  right: 0;
  top: -3px;
  font-size: 18px;
  color: var(--color-orange);
  background-color: transparent;
  cursor: pointer;
}
.wh-form__arraydelete::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e9fd"; /* ci-trash_full */
}

.wh-form__arrayadd {
  background-color: var(--color-blue);
  border: 0 none;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  border-radius: 4px;
  padding: 7px 15px 8px 35px;
  position: relative;
  display: inline-block;
}
.wh-form__fieldgroup--array.wh-form__array--maxrows .wh-form__arrayadd {
  display: none;
}
.wh-form__arrayadd::before {
  content: "Toevoegen";
}
.wh-form__arrayadd::after {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -9px;
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea22"; /* ci-plus */
}

.wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow {
  display: flex;
  justify-content: space-between;
}
.wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea {
  flex: 1 1 100%;
  padding: 0 30px 0 20px;
}
.wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea textarea, .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea .wh-form__fields, .wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--textarea .wh-form__fieldline {
  height: 100%;
}

.wh-form__fieldgroup--array.subfieldscolumns .wh-form__arrayrow .wh-form__fieldgroup--imgedit + .wh-form__fieldgroup--textarea textarea {
  max-height: 200px;
}