/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-downloads {
  margin-bottom: 50px;
}
.widget-downloads a {
  color: inherit;
  text-decoration: none;
}
.widget-downloads h2 {
  text-align: center;
}
.widget-downloads__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid var(--color-blue-light);
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  padding: 0 var(--content-padding);
}
.widget-downloads__item__link {
  padding: 25px 0 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-downloads__item__link:hover .widget-downloads__item__title {
  text-decoration: underline;
  text-underline-position: under;
}
.widget-downloads__item__icon, .widget-downloads__item__type {
  flex: none;
}
.widget-downloads__item__type {
  color: var(--color-orange);
  font-size: 24px;
}
.widget-downloads__item__icon {
  font-size: 22px;
}
.widget-downloads__item__title {
  font-size: 16px;
  font-weight: 700;
  padding: 0 var(--grid-gap);
  flex: 1 1 auto;
}
.widget-downloads__item + .widget-downloads__item {
  border-top: 1px solid var(--color-blue-line);
}