/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.contactpage-contactblocks {
  background-color: var(--color-blue-lighter);
  margin-top: -60px;
  padding: 0 30px 50px;
  border-radius: 0 0 8px 8px;
  margin-bottom: 50px;
}
.contactpage-search {
  background-color: var(--color-blue-lighter);
  padding: 50px 30px;
  border-radius: 8px;
}
.contactpage-search__header {
  text-align: center;
}
.contactpage-search__form {
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  height: 70px;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  border: 1px solid rgba(30, 17, 127, 0.1);
}
.contactpage-search__form button {
  flex: none;
  width: 70px;
  height: 100%;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
}
.contactpage-search__form input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 100%;
  border: 0 none;
  background-color: transparent;
  font-size: 19px;
  display: block;
  min-width: 175px;
  color: inherit;
  margin-right: 15px;
  flex: 1;
  font: inherit;
}
.contactpage-search__noresults {
  text-align: center;
}
.contactpage-search__noresults[hidden] {
  display: none;
}
.contactpage-search__results {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.contactpage-search__results__locations, .contactpage-search__results__persons {
  flex: 1 1 50%;
  max-width: calc(50% - 30px);
}
.contactpage-search__results--locations .contactpage-search__results__locations {
  flex: none;
  max-width: 100%;
}
.contactpage-search__results--locations .contactpage-search__results__locations .contactpage-search__results__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contactpage-search__results--locations .contactpage-search__results__locations .contactpage-search__results__list .contactpage-search__results__label {
  flex: none;
  width: 100%;
}
.contactpage-search__results--locations .contactpage-search__results__locations .contactpage-search__results__list .contactpage-search__results__item {
  flex: 1 1 50%;
  max-width: calc(50% - 30px);
}
@media (max-width: 900px) {
  .contactpage-search__results {
    display: block;
  }
  .contactpage-search__results__locations, .contactpage-search__results__persons {
    max-width: 100%;
  }
  .contactpage-search__results--locations .contactpage-search__results__locations .contactpage-search__results__list {
    display: block;
  }
  .contactpage-search__results--locations .contactpage-search__results__locations .contactpage-search__results__list .contactpage-search__results__item {
    max-width: 100%;
  }
}
.contactpage-search__results__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.contactpage-search__results__label {
  color: var(--color-orange);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 15px;
}
.contactpage-search__results__item {
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #EAEDF7;
  border-radius: 4px;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  padding: 30px;
  font-size: 16px;
}
.contactpage-search__results__item:not([hidden]) {
  display: flex;
}
.contactpage-search__results__item h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
.contactpage-search__results__item__description {
  margin-top: 10px;
  display: block;
}
.contactpage-search__results__item__description[hidden] {
  display: none;
}
.contactpage-search__results__item__description > *:last-child {
  margin-bottom: 0;
}
.contactpage-search__results__item__email {
  margin-top: 10px;
}
.contactpage-search__results__item__email a {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  align-items: baseline;
}
.contactpage-search__results__item__email a i {
  color: var(--color-orange);
  flex: none;
  font-size: 18px;
  width: 30px;
  position: relative;
  top: 2px;
}
.contactpage-search__results__item__email a:hover > span {
  text-decoration: underline;
  text-underline-position: under;
}
.contactpage-search__results__item__image {
  flex: none;
  width: 80px;
  margin-right: 30px;
}
.contactpage-search__results__item__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 50%;
}
@media (max-width: 500px) {
  .contactpage-search__results__item {
    flex-flow: column;
    align-items: center;
  }
  .contactpage-search__results__item__image {
    margin-bottom: 20px;
    margin-right: 0;
  }
}