/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-videos {
  margin-bottom: 50px;
}
.widget-videos__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-between;
  margin-left: -30px;
  padding-bottom: 30px;
  min-width: 100%;
}
.widget-videos__item {
  flex: none;
  width: calc(33.333% - 30px);
  margin-left: 30px;
  max-width: 320px;
  min-width: 270px;
}
@media (max-width: 500px) {
  .widget-videos__item {
    flex: none;
    min-width: 0;
    width: 65vw;
  }
}
.widget-videos__item__video {
  position: relative;
  border-radius: 8px;
}
.widget-videos__item__video::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-videos__item__video__play {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget-videos__item__video__play::after {
  border-radius: 50%;
  font-size: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  content: "\ea78";
  width: 70px;
  height: 70px;
  transition: background-color 300ms;
}
.widget-videos__item__video__play:hover::after {
  background-color: var(--color-orange-dark);
}
.widget-videos__item__title {
  margin-top: 20px;
  font-weight: 700;
  font-size: 21px;
}
@media (max-width: 650px) {
  .widget-videos__item__title {
    font-size: 18px;
  }
}
.widget-videos__more {
  margin-top: 30px;
  text-align: center;
}