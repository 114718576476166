/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.homepagequicklinks {
  position: relative;
}
.homepagequicklinks__container {
  max-width: var(--content-wide);
  width: 100%;
  position: relative;
  top: -60px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  min-height: 120px;
  box-shadow: 0 12px 24px 0 rgba(158, 169, 207, 0.4);
  display: flex;
}
.homepagequicklinks__col {
  max-width: 33.3333%;
  flex: 1 1 33.3333%;
  padding: 45px 30px;
  background-color: #fff;
}
.homepagequicklinks__col > *:last-child {
  margin-bottom: 0;
}
.homepagequicklinks__col + .homepagequicklinks__col {
  color: #fff;
  background-color: var(--color-blue);
}
.homepagequicklinks__col + .homepagequicklinks__col p {
  color: inherit;
}
.homepagequicklinks__col + .homepagequicklinks__col a {
  color: inherit;
  text-decoration: none;
}
.homepagequicklinks__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.homepagequicklinks__list li {
  margin-top: 15px;
}
.homepagequicklinks__link {
  display: flex;
  align-items: baseline;
}
.homepagequicklinks__link:hover > span {
  text-underline-position: under;
  text-decoration: underline;
}
.homepagequicklinks__link__icon {
  flex: none;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  position: relative;
  top: 9px;
}
.homepagequicklinks__link__icon > svg {
  width: 32px;
  height: auto;
  fill: #fff;
}
@media (max-width: 950px) {
  .homepagequicklinks {
    padding-bottom: 40px;
  }
  .homepagequicklinks__container {
    top: -10px;
    flex-wrap: wrap;
  }
  .homepagequicklinks__col {
    max-width: 50%;
    flex-basis: 50%;
    padding: 30px;
  }
  .homepagequicklinks__col:first-child {
    flex: none;
    width: 100%;
    max-width: 100%;
    order: 2;
  }
}
@media (max-width: 650px) {
  .homepagequicklinks {
    padding-top: 15px;
  }
  .homepagequicklinks::before {
    content: "";
    display: block;
    position: absolute;
    top: -130px;
    right: -20px;
    bottom: -50px;
    left: -20px;
    z-index: -1;
    background-color: var(--color-blue-lighter);
  }
  .homepagequicklinks__container {
    top: 0;
  }
  .homepagequicklinks__col {
    flex: none;
    width: 100%;
    max-width: 100%;
  }
  .homepagequicklinks__col:last-child {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}