/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.footer {
  padding: 0 var(--content-padding);
  max-width: 1440px;
}
html.mobilenav .footer {
  padding: 0 var(--content-padding-mobile);
}
@media (max-width: 600px) {
  .footer {
    padding: 0 !important;
  }
}
.footer a {
  color: inherit;
  text-decoration: none;
}
.footer a:hover {
  background-color: transparent;
  text-decoration: underline;
}
.footer__container {
  background-color: var(--color-blue);
  color: #fff;
  border-radius: 8px 8px 0 0;
  padding: 50px var(--content-padding);
}
@media (max-width: 600px) {
  .footer__container {
    padding: 30px var(--content-padding-mobile) 15px;
  }
}
html.mobilenav .footer__container {
  padding: 30px var(--content-padding-mobile) 15px;
}
.footer__container p {
  color: inherit;
}
.footer__bottom {
  background-color: var(--color-blue);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px var(--content-padding);
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
@media (max-width: 600px) {
  .footer__bottom {
    padding: 10px var(--content-padding-mobile);
  }
}
html.mobilenav .footer__bottom {
  padding: 10px var(--content-padding-mobile);
}
.footer__bottom__nav {
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.footer__bottom a {
  color: inherit;
  display: inline-block;
  margin-right: 25px;
  transition: color 300ms;
}
.footer__bottom a:hover {
  color: #fff;
  text-decoration: underline;
  text-underline-position: under;
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
}
.footer__block {
  flex: 1 1 50%;
  max-width: 50%;
  margin-top: 30px;
}
.footer__block--right {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 900px) {
  .footer__block--text {
    margin-top: 0;
  }
  .footer__block--text, .footer__block--nav {
    flex: none;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .footer__inner {
    display: block;
  }
  .footer__block {
    max-width: 100%;
  }
}
.footer__contacttext {
  padding-right: 80px;
  font-size: 16px;
}
.footer__contacttext > *:last-child {
  margin-bottom: 0;
}
.footer__nav {
  display: flex;
  flex-wrap: wrap;
}
.footer__nav__link {
  display: block;
  flex: 1 1 50%;
  max-width: 50%;
  color: inherit;
  font-weight: 700;
  padding-right: 30px;
  margin-bottom: 15px;
}
.footer__nav__link__label {
  display: inline-block;
  border-radius: 4px;
  font-size: 15px;
  padding: 3px 8px;
  margin-left: 10px;
  line-height: 19px;
  background-color: var(--color-orange);
}
.footer__nav__link:hover {
  text-decoration: underline;
  text-underline-position: under;
}
@media (max-width: 750px) {
  .footer__nav__link {
    font-size: 15px;
  }
}
.footer__socialmedia {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}
.footer__socialmedia .footer__socialmedia__link {
  margin: 0 0 15px 15px;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: border-color 300ms;
}
.footer__socialmedia .footer__socialmedia__link:hover {
  border-color: rgb(255, 255, 255);
  text-shadow: 0 0 1px #fff;
  text-decoration: none;
}
.footer__translate {
  text-align: center;
}
.footer__translate .goog-te-gadget, .footer__translate .goog-te-gadget a {
  font-family: inherit;
  color: #fff;
}
.footer__translate .wh-styledinput select, .footer__translate .wh-styledinput .wh-styledinput select:focus, .footer__translate .wh-styledinput select:focus-within {
  border-color: #D8D8D8 !important;
  color: #fff;
  background-color: #1E117F;
}
.footer__translate .wh-styledinput option { /* supported on windows, else pulldown text is not readable because of white text color */
  background-color: #1E117F;
  color: #fff;
}
.footer__translate .wh-styledinput .wh-form__pulldown-styled .arrow {
  color: #fff;
}
.footer__translate .wh-styledinput .wh-form__pulldown-styled::after {
  display: none;
}