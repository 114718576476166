/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-mvo {
  border-radius: 8px;
  padding: 85px 30px 0;
  margin-bottom: 170px;
  background: var(--color-blue-lighter) url("mvo.png") 93% 0 no-repeat;
  background-size: 222px;
}
@media (max-width: 1380px) {
  .widget-mvo {
    background-size: 16vw;
  }
}
@media (max-width: 750px) {
  .widget-mvo {
    background-size: 120px;
  }
}
.widget-mvo__suptitle {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
.widget-mvo__text {
  text-align: center;
}
.widget-mvo__text > *:last-child {
  margin-bottom: 0;
}
.widget-mvo__text .button {
  text-transform: uppercase;
}
.widget-mvo__video {
  margin-top: 50px;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  border-radius: 8px;
  margin-bottom: -120px;
}