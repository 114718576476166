@import "./shared/config-vars.css";
/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
@import "./web/fonts/coolicons/coolicons.css";
@import "./web/fonts/wp-extraicons/wp-extraicons.css";
html {
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  min-height: 100%;
  /* overwrite google translate style */
}
html.translated-rtl p, html.translated-rtl h1, html.translated-rtl h2, html.translated-rtl h3, html.translated-rtl h4, html.translated-rtl ul, html.translated-rtl li {
  direction: rtl;
}
html[style] {
  height: auto !important;
}
html.homepage h2 {
  font-size: 56px;
}

/* responsive font sizes */
@media (max-width: 750px) {
  html {
    font-size: 16px;
  }
  p.intro {
    font-size: 17px;
  }
}
html.mobilenav .wh-anchor {
  scroll-margin-top: 80px;
}

@media (max-width: 1380px) {
  h1, h2.heading1, html.homepage h2 {
    font-size: 4.0579710145vw;
  }
}
@media (max-width: 739.2857142857px) {
  h1, h2.heading1, html.homepage h2 {
    font-size: 30px;
  }
}

@media (max-width: 1380px) {
  h2, html.klantportaal h1 {
    font-size: 2.6086956522vw;
  }
}
@media (max-width: 843.3333333333px) {
  h2, html.klantportaal h1 {
    font-size: 22px;
  }
}

@media (max-width: 1380px) {
  h3, html.klantportaal h2 {
    font-size: 1.884057971vw;
  }
}
@media (max-width: 1061.5384615385px) {
  h3, html.klantportaal h2 {
    font-size: 20px;
  }
}

@media (max-width: 1380px) {
  html.klantportaal h3 {
    font-size: 1.7391304348vw;
  }
}
@media (max-width: 1150px) {
  html.klantportaal h3 {
    font-size: 20px;
  }
}

@media (max-width: 1380px) {
  h4 {
    font-size: 1.3768115942vw;
  }
}
@media (max-width: 1234.7368421053px) {
  h4 {
    font-size: 17px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100vw;
  min-height: 100%;
  min-width: 320px;
  position: relative;
  -webkit-overflow-scrolling: auto;
  /* ignore/hide google translate suggest bar */
}
body[style] {
  top: 0 !important;
}
body > .skiptranslate {
  display: none !important;
}

main {
  flex: 1 1 auto;
  margin: 0 var(--content-padding) 50px;
}
@media (max-width: 600px) {
  main {
    margin: 0 var(--content-padding-mobile) 30px;
  }
}
html.mobilenav main {
  margin: 0 var(--content-padding-mobile) 30px;
}

header, footer {
  flex: none;
}

.sidenav {
  max-width: 250px;
  margin-right: var(--grid-gap);
  font-size: 16px;
  margin-bottom: 50px;
}
html.mobilenav .sidenav {
  display: none;
}
.sidenav a {
  color: inherit;
  text-decoration: none;
  transition: color 300ms;
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.sidenav__backlink {
  font-weight: 700;
}
.sidenav__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidenav__item {
  padding: 20px 0;
  border-bottom: 1px solid var(--color-blue-line);
}
.sidenav__item--active a, .sidenav a:hover {
  color: var(--color-orange-hover);
}

.pagemorecolumn {
  margin-top: -10px;
  display: flex;
  justify-content: flex-end;
}

.structuredbreadcrumb {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  white-space: nowrap;
  font-size: 16px;
  max-width: var(--content-wide);
  width: 100%;
  margin: 0 auto;
}
.structuredbreadcrumb li {
  display: inline;
  padding: 0 5px 0 0;
}
.structuredbreadcrumb li + li::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e95d";
  padding-right: 5px;
  position: relative;
  top: 2px;
}
.structuredbreadcrumb li > span {
  opacity: 0.6;
}
@media (max-width: 650px) {
  .structuredbreadcrumb {
    font-size: 14px;
  }
  html:not(.klantportaal) .structuredbreadcrumb li:first-child a span {
    display: none;
  }
  html:not(.klantportaal) .structuredbreadcrumb li:first-child a::before {
    font-family: "coolicons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    content: "\e916";
    font-size: 16px;
  }
}
.structuredbreadcrumb a {
  text-decoration: none;
  color: inherit;
  font-weight: 700;
}
.structuredbreadcrumb a:hover {
  text-decoration: underline;
  text-underline-position: under;
}

.errorcontentheader {
  border-radius: 8px;
  background-color: var(--color-blue-lighter);
  padding: 50px 30px;
  margin-bottom: 50px;
}
.errorcontentheader h1 {
  text-align: center;
}

.defaultcontentheader {
  border-radius: 8px;
  background-color: var(--color-blue-lighter);
  padding: 0 30px 50px;
  margin-bottom: 50px;
}
@media (max-width: 600px) {
  .defaultcontentheader {
    padding: 0 20px 30px;
  }
}
.defaultcontentheader__inner {
  max-width: var(--content-xsmall);
  width: 100%;
  margin: 0 auto;
}
.defaultcontentheader__inner > *:last-child {
  margin-bottom: 0;
}
.defaultcontentheader h1 + p {
  margin-top: 20px;
}
.defaultcontentheader h1, .defaultcontentheader p {
  text-align: center;
}
.defaultcontentheader h1 {
  margin-top: 30px;
}
.defaultcontentheader .pagedate {
  margin-top: 20px;
  text-align: center;
  margin-bottom: -20px;
  font-size: 16px;
  color: var(--color-orange);
}
.defaultcontentheader .pagesuptitle {
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.defaultcontentheader .pagesuptitle + h1 {
  margin-top: 5px;
}

.sharepage {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.sharepage__label {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 15px;
}
.sharepage__items {
  display: flex;
}
.sharepage__items button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 20px;
  flex: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  border: 2px solid var(--color-blue-line);
  transition: border-color 300ms;
}
.sharepage__items button:hover, .sharepage__items button:focus {
  border-color: var(--color-blue);
}

.cookiebar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 0;
  transition: max-height 300ms;
  background-color: var(--color-blue-lighter);
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05);
}
.cookiebar--show {
  max-height: 100vh;
}
.cookiebar__inner {
  padding: var(--grid-gap);
  text-align: center;
}
html.mobilenav .cookiebar__inner {
  padding: var(--content-padding-mobile);
}
@media (max-width: 650px) {
  .cookiebar__inner {
    padding: var(--content-padding-mobile);
  }
}
.cookiebar__buttons {
  display: flex;
  justify-content: center;
}

/* small animation fx when an element with this class gets into view while scrolling */
.scrollfx {
  position: relative;
  transform: translate3d(0, 70px, 0);
  opacity: 0.1;
  transition-property: transform, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.scrollfx--fromtop {
  transform: translate3d(0, -70px, 0);
}
html.wh-widgetpreview .scrollfx--done, .scrollfx--done {
  transform: translate3d(0, 0, 0);
  opacity: 0.999;
}