@import "https://www.dewoonplaats.nl/.woonplaats_digitaal/assets/hankengrotesk/hankengrotesk.css";

html
{
  color: var(--color-blue);
  font: 18px/165% "Hanken Grotesk", "sans-serif", Arial;
}

html.klantportaal
{
  font-size: 16px;
}

h1, h2, h3
{
  line-height: 115%;
}
h1, h2.heading1
{
  font-size: 56px;
}
h2, html.klantportaal h1
{
  font-size: 36px;
  margin-bottom: 20px;
}
h3, html.klantportaal h2
{
  font-size: 26px;
  margin-bottom: 10px;
}
html.klantportaal h3
{
  font-size: 24px;
}
h4
{
  font-size: 19px;
  line-height: 170%;
  margin-bottom: 0;
}

div.alertbox
{
  padding: 15px !important;
  border: 1px solid rgb(255, 167, 127);
  background-color: rgb(255, 243, 243);
  border-radius: 4px;
  margin-bottom: 25px;
  font-size: 17px;
}
html.klantportaal div.alertbox
{
  font-size: 15px;
}

p.intro
{
  font-size: 19px;
  line-height: 165%;
  color: var(--color-blue-intro);
}
html.klantportaal p.intro
{
  font-size: 18px;
}

p a, .unordered a, .ordered a
{
  color: inherit;
  text-underline-position: under;
  text-decoration-thickness: 1px;
  transition: background-color 300ms;
}
p a:hover, .unordered a:hover, .ordered a:hover
{
  background-color: var(--color-blue-lighter);
}
p
{
  color: var(--color-blue-normal);
  margin-bottom: 30px;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  color: var(--color-blue-normal);
  margin: 0 0 30px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-bottom: 0;
}

  p + ul.unordered
, p + ol.ordered
{
  margin-top: -25px;
}

  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  padding: 0;
  line-height: 165%;
  list-style-type: none;
}

  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 4px 25px;
  min-height: 15px;
}

  ul.unordered li::before
, ol.ordered li::before
{
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
  ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 25px;
}

  ul.unordered > li::before
, ul.unordered ul > li::before
, ol.ordered ul > li::before
{
  content: "\25cf"; /* solid circle */
  text-align: center;
  font-size: 10px;
}

  ol.ordered
, ol.ordered ol
, ul.unordered ol
{
  counter-reset: numlist;
}
  ol.ordered > li:before
, ol.ordered ol > li:before
, ul.unordered ol > li:before
{
  counter-increment: numlist;
  content: counter(numlist, decimal) ".";
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}
