/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.sitenotification {
  background-color: #FFF5E9;
  padding: 0 var(--content-padding);
}
html.mobilenav .sitenotification {
  padding: 0 var(--content-padding-mobile);
}
.sitenotification__inner {
  display: flex;
  align-items: flex-start;
}
.sitenotification__content {
  padding: 15px 0;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: hidden;
  display: flex;
}
.sitenotification__icon {
  width: 28px;
  flex: none;
  margin-right: 15px;
}
.sitenotification__icon svg {
  width: 100%;
  height: auto;
  fill: var(--color-orange);
}
.sitenotification__text {
  line-height: 150%;
}
.sitenotification__text > *:last-child {
  margin-bottom: 0;
}
.sitenotification__close {
  flex: none;
  font-size: 20px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  margin-top: 13px;
  padding: 6px;
}