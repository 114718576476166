html:not(.wh-authormode--active) .dwp-standardtext__start, html:not(.wh-authormode--active) .dwp-standardtext__end, html:not(.wh-authormode--active) .dwp-standardtext__notset {
  display: none;
}

html.wh-authormode--active .dwp-standardtext__start {
  border-bottom: 1px dotted #777;
  color: #777;
  font-size: 70%;
}
html.wh-authormode--active .dwp-standardtext__start a {
  color: #777;
}

html.wh-authormode--active .dwp-standardtext__end {
  border-bottom: 1px dotted #777;
}

.dwp-standardtext__notset {
  font-style: italic;
}