/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-faq {
  margin-bottom: 50px;
}
.widget-faq__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--color-blue-line);
}
.widget-faq__item {
  border-bottom: 1px solid var(--color-blue-line);
}
.widget-faq .wh-details__container__inner {
  background-color: rgba(234, 237, 247, 0.2);
  padding: var(--content-padding);
}
.widget-faq .wh-details__summary {
  padding: 20px 45px 17px 15px;
  position: relative;
  z-index: 0;
}
.widget-faq .wh-details__summary::before {
  content: "";
  display: block;
  position: absolute;
  background-color: var(--color-blue-lighter);
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
  transition: width 200ms, opacity 200ms;
}
.widget-faq .wh-details__summary:hover::before {
  opacity: 0.35;
  width: 100%;
}
.widget-faq .wh-details__summary h4 {
  line-height: 130%;
  font-size: 18px;
  margin-bottom: 0;
}
.widget-faq .wh-details__summary::after {
  position: absolute;
  display: block !important;
  right: 15px;
  top: 50%;
  margin-top: -9px;
  font-size: 16px;
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: var(--color-blue);
  content: "\ea22";
}
.widget-faq .wh-details--open > .wh-details__summary::after {
  content: "\ea30";
}