/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.centercontent-xwide > .widget-imageslideshow {
  margin-left: -30px;
  margin-right: -30px;
}
html.mobilenav .centercontent-xwide > .widget-imageslideshow {
  margin-left: -20px;
  margin-right: -20px;
}

.defaultcontentheader .widget-imageslideshow {
  max-width: calc(100% + 60px);
  width: auto;
  margin-top: 30px;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -50px;
}

.widget-imageslideshow {
  position: relative;
}
.widget-imageslideshow__container {
  overflow: hidden;
  position: relative;
  min-height: 180px;
}
.widget-imageslideshow__container::after {
  content: "";
  display: block;
  padding-top: 42.65%;
  width: 100%;
}
.widget-imageslideshow--skewed .widget-imageslideshow__container {
  -webkit-clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
  border-radius: 0 0 10px 10px;
}
.widget-imageslideshow--rounded .widget-imageslideshow__container {
  border-radius: 8px;
}
.widget-imageslideshow__text {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding: 100px;
  background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0%, rgba(38, 33, 81, 0.65) 100%);
  overflow: hidden;
  color: #fff;
}
.widget-imageslideshow__text > * {
  max-width: 365px;
}
.widget-imageslideshow__text > *:last-child {
  margin-bottom: 0;
}
.widget-imageslideshow__text p, .widget-imageslideshow__text h1 {
  color: inherit;
}
.widget-imageslideshow__text h1 {
  font-size: 42px;
  margin-bottom: 20px;
}
@media (max-width: 1380px) {
  .widget-imageslideshow__text h1 {
    font-size: 3.0434782609vw;
  }
}
@media (max-width: 690px) {
  .widget-imageslideshow__text h1 {
    font-size: 21px;
  }
}
.widget-imageslideshow__slides {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-width: 100%;
  display: inline-flex;
  transition: transform 300ms;
}
.widget-imageslideshow__slide {
  flex: none;
  width: 100%;
  position: relative;
  display: block;
}
.widget-imageslideshow__button-previous, .widget-imageslideshow__button-next {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  color: #fff;
  background-color: var(--color-orange);
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 80px;
  font-size: 28px;
  transition: background-color 300ms;
}
.widget-imageslideshow__button-previous:hover, .widget-imageslideshow__button-next:hover {
  background-color: var(--color-orange-hover);
}
.widget-imageslideshow__button-previous {
  left: -30px;
  border-radius: 0 8px 8px 0;
}
html.mobilenav .widget-imageslideshow__button-previous {
  width: 50px;
  left: -20px;
}
.widget-imageslideshow__button-next {
  right: -30px;
  border-radius: 8px 0 0 8px;
}
html.mobilenav .widget-imageslideshow__button-next {
  width: 50px;
  right: -20px;
}
@media (max-width: 1000px) {
  .widget-imageslideshow__text {
    padding: 10vw;
  }
}
@media (max-width: 650px) {
  .widget-imageslideshow__text {
    padding: 15px 30px;
    background: linear-gradient(0deg, rgba(38, 33, 81, 0.65) 0%, rgba(38, 33, 81, 0) 100%);
    left: 0;
  }
  .widget-imageslideshow__text p {
    font-size: 15px;
  }
  .widget-imageslideshow__text > * {
    max-width: 100%;
  }
}

@media (max-width: 650px) {
  main > .widget-imageslideshow:first-child .widget-imageslideshow__button-previous, main > .widget-imageslideshow:first-child .widget-imageslideshow__button-next {
    display: none;
  }
}