/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-locations {
  margin-bottom: 30px;
  margin-top: 30px;
}
.widget-locations__blocklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  margin-left: -30px;
  padding-bottom: 30px;
}
.widget-locations__block {
  background-color: #fff;
  padding: 40px;
  margin-left: 30px;
  flex: 1 1 33.3333%;
  max-width: calc(33.3333% - 30px);
  display: flex;
  flex-flow: column;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  border-radius: 4px;
  border: 1px solid #EAEDF7;
  font-size: 16px;
  min-width: 270px;
}
.widget-locations__block__header {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  flex: none;
  color: var(--color-orange);
  font-weight: 700;
  text-transform: uppercase;
}
.widget-locations__block__icon {
  flex: none;
  width: 32px;
  margin-right: 15px;
  position: relative;
  top: -2px;
}
.widget-locations__block__icon svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  fill: var(--color-orange);
}
.widget-locations__block__linklist {
  flex: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}
.widget-locations__block__linklist__link {
  display: block;
  position: relative;
  padding-left: 25px;
  color: inherit;
  text-decoration: none;
}
.widget-locations__block__linklist__link:hover > span {
  text-decoration: underline;
  text-underline-position: under;
}
.widget-locations__block__linklist__link::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 9px;
  content: "\e978";
  font-size: 14px;
}
.widget-locations__block__description {
  font-size: 16px;
  flex: 1 1 auto;
}
.widget-locations__block__description > *:last-child {
  margin-bottom: 0;
}