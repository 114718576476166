@charset "UTF-8";
.wh-form__imgedit:not(.wh-form__imgedit--hasimage) {
  border: 1px solid #acacac;
}
.wh-form__imgedit:not(.wh-form__imgedit--hasimage):after {
  content: "";
  position: absolute;
  background: url(upload.svg) center no-repeat;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.wh-form__imgeditdelete {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  color: white;
  padding: 4px;
}
.wh-form__imgeditdelete:before {
  content: "X";
}

.wh-form__uploadfielddelete {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  color: white;
  padding: 3px;
  font-size: 70%;
}
.wh-form__uploadfielddelete:before {
  content: "X";
}
.wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}

.wh-form__arrayadd {
  background: black;
  color: white;
  padding: 3px;
  font-size: 70%;
}
.wh-form__arrayadd:before {
  content: "+";
}
.wh-form__fieldgroup--array.wh-form__array--maxrows .wh-form__arrayadd {
  display: none;
}

.wh-form__arraydelete {
  background: black;
  color: white;
  padding: 3px;
  font-size: 70%;
}
.wh-form__arraydelete:before {
  content: "X";
}

.datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  border: 1px inset #cbcbcb;
  padding: 1px 2px;
}
.datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.datetime__sep {
  display: inline-block;
}
.datetime__part {
  background: transparent;
  border: 0;
  width: calc(2ch + 10px);
  text-align: center;
  font: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.datetime__part::-webkit-outer-spin-button, .datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datetime__year {
  width: calc(4ch + 8px);
}
.datetime__msec {
  width: calc(3ch + 8px);
}
.datetime__togglepicker, .datetime__reset {
  display: inline-block;
  cursor: pointer;
}
.datetime__picker {
  position: fixed;
  background-color: #ffffff;
}
.datetime__togglepicker:before {
  content: "˅";
}
.datetime__reset:before {
  content: "x";
}