.dwp-daypart {
  position: relative;
}
.dwp-daypart__daylist {
  display: table;
}
.dwp-daypart__day {
  display: table-row;
}
.dwp-daypart__repairdate, .dwp-daypart__repairoption {
  display: table-cell;
  vertical-align: top;
  padding: 4px 0 5px 0;
}
.wh-form__fieldgroup--error .dwp-daypart__repairdate {
  color: var(--color-red);
}
.dwp-daypart__repairoption {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
}
.dwp-daypart__repairoption input + label {
  margin-top: -4px;
}
.dwp-daypart__partlabel {
  margin-left: 5px;
}
.dwp-daypart .dwp-inputerrorholder {
  position: absolute;
  top: 2px;
  right: 2px;
}