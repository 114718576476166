.dwp-dmsupload {
  width: 100%;
}
.dwp-dmsupload .wh-form__uploadfield {
  display: flex;
}
.dwp-dmsupload .wh-form__uploadfieldfilename {
  width: 100%;
}
.dwp-dmsupload .wh-form__uploadfieldselect {
  flex: none;
  margin-right: 15px;
}
.dwp-dmsupload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
}
.dwp-dmsupload .dwp-dmsupload__file {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-blue);
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 10px 15px 10px 20px;
  line-height: 28px;
  background-color: #F1F3FA;
}
.dwp-dmsupload .dwp-dmsupload__file-type {
  flex: none;
  margin-right: 15px;
}
.dwp-dmsupload .dwp-dmsupload__file__name {
  flex: 1 1 auto;
}
.dwp-dmsupload .dwp-dmsupload__file-remove {
  flex: none;
  padding: 0 5px;
  color: var(--color-orange-dark);
}