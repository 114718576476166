/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-image {
  margin-bottom: 50px;
}
.widget-image__image {
  width: 100%;
}
.widget-image__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 8px;
}
.widget-image__caption {
  margin-top: 15px;
  font-style: italic;
  font-size: 15px;
  line-height: 165%;
}