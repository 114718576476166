/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
main > .wh-video, .maingrid__xsmall > .wh-video {
  margin-bottom: 50px;
}

.wh-video__innerframe__preview, .wh-video iframe {
  border-radius: 8px;
}

.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  transition: background-color 0.3s;
  color: #fff;
  font-size: 40px;
}
.wh-video__playbutton::after {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  content: "\ea78";
}
@media (max-width: 500px) {
  .wh-video__playbutton {
    width: 20vw;
    height: 20vw;
  }
}

.wh-video__innerframe__preview:hover .wh-video__playbutton {
  background-color: var(--color-orange-dark);
}

.maingrid > .wh-video {
  margin-bottom: 50px;
}