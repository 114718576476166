/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-quicklinks {
  margin-bottom: 50px;
}
.widget-quicklinks__title {
  margin-bottom: 30px;
}
.widget-quicklinks__itemlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -20px;
}
.widget-quicklinks__item {
  flex: 1 1 25%;
  max-width: 33.333%;
  padding-left: 20px;
  padding-bottom: 20px;
}
.widget-quicklinks__item__link {
  height: 100%;
  color: inherit;
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
  padding: var(--content-padding);
  text-transform: uppercase;
  background-color: var(--color-blue-light);
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background-color 300ms, color 300ms;
}
.widget-quicklinks__item__title {
  text-underline-position: under;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.widget-quicklinks__item__link:hover {
  background-color: var(--color-blue);
  color: #fff;
}
.widget-quicklinks__item__icon {
  flex: none;
  font-size: 22px;
}
.widget-quicklinks__itemlist[data-maxcols="4"] .widget-quicklinks__item {
  max-width: 25%;
}
@media (max-width: 1000px) {
  .widget-quicklinks__itemlist[data-maxcols="4"] .widget-quicklinks__item {
    max-width: 33.333%;
  }
}
@media (max-width: 750px) {
  .widget-quicklinks__item, .widget-quicklinks__itemlist[data-maxcols="4"] .widget-quicklinks__item {
    max-width: 50%;
  }
}
@media (max-width: 500px) {
  .widget-quicklinks__item, .widget-quicklinks__itemlist[data-maxcols="4"] .widget-quicklinks__item {
    max-width: 100%;
  }
}