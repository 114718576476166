/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-contactblocks {
  background-color: var(--color-blue-lighter);
  padding: 50px 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.widget-contactblocks:last-child {
  margin-bottom: 0;
}
.widget-contactblocks__header {
  text-align: center;
}
.widget-contactblocks__header > *:last-child {
  margin-bottom: 0;
}
.widget-contactblocks__blocklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  margin-left: -30px;
  padding-bottom: 30px;
}
.widget-contactblocks__block {
  background-color: #fff;
  padding: 40px;
  margin-left: 30px;
  flex: 1 1 33.3333%;
  max-width: calc(33.3333% - 30px);
  display: flex;
  flex-flow: column;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  border-radius: 4px;
  min-width: 300px;
}
.widget-contactblocks__block__header {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  flex: none;
}
.widget-contactblocks__block__header a {
  color: inherit;
  text-decoration: none;
}
.widget-contactblocks__block__header i {
  position: relative;
  flex: none;
  top: -2px;
  width: 50px;
  font-size: 34px;
  color: var(--color-orange);
}
.widget-contactblocks__block--mydwp {
  background-color: var(--color-blue);
  color: #fff;
}
.widget-contactblocks__block--mydwp p {
  color: inherit;
}
.widget-contactblocks__block--mydwp .widget-contactblocks__block__header i {
  color: #fff;
}
.widget-contactblocks__block__linklist {
  flex: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  font-size: 16px;
}
.widget-contactblocks__block__linklist__link {
  display: block;
  position: relative;
  padding-left: 25px;
  color: inherit;
  text-decoration: none;
}
.widget-contactblocks__block__linklist__link:hover > span {
  text-decoration: underline;
  text-underline-position: under;
}
.widget-contactblocks__block__linklist__link::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 9px;
  content: "\e978";
  font-size: 14px;
}
.widget-contactblocks__block .button {
  text-transform: uppercase;
}
.widget-contactblocks__block__description {
  font-size: 16px;
  flex: 1 1 auto;
}
.widget-contactblocks__block__description > *:last-child {
  margin-bottom: 0;
}
.widget-contactblocks__block__description + .button {
  align-self: flex-start;
  flex: none;
  margin-top: 30px;
}