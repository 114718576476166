/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.widget-photoalbum {
  margin-bottom: 50px;
}
.widget-photoalbum__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.widget-photoalbum__preview {
  flex: none;
  width: calc(68% - 30px);
  margin-bottom: 30px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.widget-photoalbum__preview--video::after {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  content: "\ea78";
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  transition: background-color 0.3s;
  color: #fff;
  font-size: 40px;
}
@media (max-width: 665px) {
  .widget-photoalbum__preview--video::after {
    width: 15vw;
    height: 15vw;
  }
}
.widget-photoalbum__preview:nth-child(3)::after {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100px;
  height: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  content: "\ea19";
  color: var(--color-blue);
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 665px) {
  .widget-photoalbum__preview:nth-child(3)::after {
    width: 15vw;
    height: 15vw;
  }
}
.widget-photoalbum__preview:nth-child(2), .widget-photoalbum__preview:nth-child(3) {
  flex: none;
  width: 32%;
}
.widget-photoalbum__preview:nth-child(2)::before, .widget-photoalbum__preview:nth-child(3)::before {
  content: "";
  display: block;
  padding-top: 100%;
}
@media (max-width: 650px) {
  .widget-photoalbum__preview {
    width: calc(68% - 20px);
    margin-bottom: 20px;
  }
}