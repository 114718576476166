/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
.questionspage {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 100px;
  max-width: var(--content-xsmall);
  z-index: 0;
}
.questionspage-wrapper {
  margin-top: -60px;
  background-color: var(--color-blue-lighter);
  padding: 0 0 100px;
  border-radius: 0 0 8px 8px;
}
.questionspage h4 {
  text-transform: uppercase;
  text-align: center;
  margin: 25px 20px;
}
.questionspage__form {
  position: relative;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  background-color: #fff;
  border: 1px solid rgba(30, 17, 127, 0.1);
  border-radius: 4px;
  padding: 15px 25px;
  margin: 0 20px 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: flex-start;
}
.questionspage__form input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  font: inherit;
  flex: 1 1 auto;
  font-size: 16px;
  border: 0 none;
  min-width: 40px;
}
.questionspage__form button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  flex: none;
  font-size: 22px;
  margin-right: 20px;
  padding-top: 3px;
}
.questionspage__form__results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms;
  display: none;
}
.questionspage__form__results__inner {
  padding: 15px 30px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  background-color: #fff;
  border: 1px solid rgba(30, 17, 127, 0.1);
}
.questionspage__form__results--active {
  display: block;
}
.questionspage__form__results--show {
  opacity: 1;
}
.questionspage__form__results h4 {
  text-transform: none;
  text-align: left;
  margin: 10px 0;
}
.questionspage__form__results a {
  color: inherit;
  text-decoration: none;
  padding: 5px 0;
  display: block;
}
.questionspage__form__results a:hover {
  text-decoration: underline;
  text-underline-position: under;
}
.questionspage__subjects {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 9px 0 rgba(158, 169, 207, 0.2);
  position: relative;
  overflow: hidden;
  display: flex;
  margin: 0 20px;
  align-items: flex-start;
  transition: height 400ms;
}
.questionspage__subjects--selected .questionspage__categories, .questionspage__subjects--selected .questionspage__items {
  transform: translateX(-100%);
}
.questionspage__categories, .questionspage__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding: 15px 30px;
  flex: none;
  width: 100%;
  position: relative;
  transition: transform 400ms;
}
.questionspage__items__back, .questionspage__category__toggle {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  outline: none;
}
.questionspage__items__back {
  font-weight: 600;
  margin: 15px 0;
  padding-left: 20px;
}
.questionspage__items__back__arrow {
  font-size: 20px;
  margin-right: 30px;
  flex: none;
  width: 32px;
}
@media (max-width: 680px) {
  .questionspage__items__back__arrow {
    margin-right: 10px;
  }
}
.questionspage__category__toggle {
  padding: 15px 0 15px 16px;
  text-align: left;
}
.questionspage__category__icon {
  flex: none;
  width: 35px;
  margin-right: 30px;
}
.questionspage__category__icon svg {
  fill: var(--color-orange);
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.questionspage__category__title {
  flex: 1 1 auto;
  padding-right: 15px;
}
.questionspage__category__arrow {
  flex: none;
  width: 32px;
  font-size: 24px;
}
.questionspage__category + .questionspage__category {
  border-top: 1px solid var(--color-blue-light);
}
.questionspage__category__toggle, .questionspage__category > .questionspage__item, .questionspage__itemlist > .questionspage__item, .questionspage__item {
  padding-left: 16px;
}
.questionspage__category__toggle, .questionspage__item {
  position: relative;
  z-index: 0;
}
.questionspage__category__toggle::before, .questionspage__item::before {
  content: "";
  display: block;
  position: absolute;
  background-color: var(--color-blue-lighter);
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
  transition: width 200ms, opacity 200ms;
}
.questionspage__category__toggle:hover::before, .questionspage__item:hover::before {
  opacity: 0.35;
  width: 100%;
}
.questionspage__item {
  color: inherit;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 16px 0;
  width: 100%;
}
.questionspage__item:hover .questionspage__item__title {
  text-decoration: underline;
  text-underline-position: under;
}
.questionspage__item__icon, .questionspage__item__arrow {
  flex: none;
  width: 32px;
  font-size: 24px;
}
.questionspage__item__icon {
  margin-right: 30px;
  width: 35px;
}
.questionspage__item__icon > svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  fill: var(--color-orange);
}
.questionspage__item__title {
  flex: 1 1 auto;
  padding-right: 15px;
}
@media (max-width: 650px) {
  .questionspage__category__toggle, .questionspage__category > .questionspage__item, .questionspage__itemlist > .questionspage__item, .questionspage__item {
    padding-left: 10px;
  }
}
.questionspage__item + .questionspage__item {
  border-top: 1px solid var(--color-blue-light);
}
@media (max-width: 680px) {
  .questionspage__form {
    margin: 0 20px 30px;
    padding: 15px;
  }
  .questionspage__form button {
    padding-left: 3px;
  }
  .questionspage__categories {
    padding: 15px 20px;
  }
  .questionspage__category__icon, .questionspage__item__icon {
    width: 24px;
    margin-right: 15px;
  }
  .questionspage__category__arrow, .questionspage__item__arrow {
    width: 20px;
    font-size: 20px;
  }
}

.questionspage-mydwp {
  margin: 0 auto;
  width: 100%;
  max-width: var(--content-xsmall);
  background-color: var(--color-blue);
  color: #fff;
  border-radius: 8px;
  padding: var(--content-padding);
  margin-top: -130px;
  margin-bottom: 130px;
  text-align: center;
}
.questionspage-mydwp p {
  color: inherit;
}
.questionspage-mydwp__link {
  background-color: var(--color-orange);
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  height: 50px;
  font-weight: 700;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
}
.questionspage-mydwp__link::before {
  font-family: "coolicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  content: "\ea08";
  flex: none;
  display: inline-flex;
  font-size: 26px;
  align-items: center;
  border-radius: 4px 0 0 4px;
  justify-content: center;
  height: 100%;
  width: 50px;
}