/** @short Apply a multi-line text-overflow ellipsis
    @description This will display an ellipsis if the the text wraps to more than the given number of lines. NOTE: This will
        overwrite the 'display' property of the element!
    @param $lines The number of lines after which the text is truncated
*/
/** @short Setup the main 12-column grid layout for the element
*/
html.klantportaal main {
  margin-bottom: 0;
}

html.klantportaal .structuredbreadcrumb {
  padding-top: 5px;
}

.klantportaal-table {
  font-size: 16px;
  border-collapse: collapse;
}
.klantportaal-table th, .klantportaal-table td {
  vertical-align: top;
  text-align: left;
}
.klantportaal-table--mypayments.complist thead th, .klantportaal-table--mypayments.complist thead td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.klantportaal-table--mypayments.complist tbody tr:first-child td {
  padding-top: 20px;
}
.klantportaal-table--mypayments.complist tbody tr:last-child td {
  border-bottom: 1px solid var(--color-blue-line);
  padding-bottom: 20px;
}
.klantportaal-table--mypayments.complist tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0 none;
}
.klantportaal-table.maillist .date, .klantportaal-table.maillist .subject {
  padding-top: 12px;
  vertical-align: top;
  padding-right: 15px;
}
.klantportaal-table.maillist .maillist__documentsoort {
  display: block;
  text-align: right;
  font-size: 70%;
}
.klantportaal-table .lineabove td, .klantportaal-table .lineabove th {
  border-top: 1px solid var(--color-blue);
}
.klantportaal-table th.totalamount {
  text-align: right;
}
.klantportaal-table td.actions {
  width: 1%;
}
.klantportaal-table td.actions .button {
  width: 100%;
}
.klantportaal-table td.actions .button + * {
  margin-top: 10px;
}
.klantportaal-table td.date {
  font-size: 90%;
  white-space: nowrap;
}
.klantportaal-table th.title {
  padding-top: 20px;
  font-size: 20px;
}
@media (max-width: 1000px) {
  .klantportaal-table th.title {
    font-size: 2vw;
  }
}
@media (max-width: 900px) {
  .klantportaal-table th.title {
    font-size: 18px;
  }
}
.klantportaal-table th.descr:not(:last-child),
.klantportaal-table thead + tbody td:not(:last-child) {
  padding-right: 15px;
}
.klantportaal-table .amount {
  white-space: nowrap;
  text-align: right;
  padding-right: 15px;
  width: 1%;
}
.klantportaal-table .points {
  text-align: right;
}
.klantportaal-table .action {
  width: 1%;
}
.klantportaal-table.complist {
  width: 100%;
}
.klantportaal-table.complist td, .klantportaal-table.complist th {
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 7px;
  border-bottom: 1px solid var(--color-blue-line);
}
.klantportaal-table.complist th.title {
  padding-top: 20px;
}

.klantportaal-mycontracts tr + tr td {
  padding-top: 10px;
}

.klantportaal-linklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid var(--color-blue-line);
  margin-top: 30px;
}
.klantportaal-linklist-title {
  margin-top: 30px;
  font-size: 17px;
}
.klantportaal-linklist-title + .klantportaal-linklist {
  margin-top: 10px;
}
.klantportaal-linklist__item {
  border-bottom: 1px solid var(--color-blue-line);
}
.klantportaal-linklist__item__link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
  transition: background-color 300ms;
}
.klantportaal-linklist__item__link:hover, .klantportaal-linklist__item__link:focus {
  background-color: var(--color-blue-lighter);
}
.klantportaal-linklist__item__title {
  flex: 1 1 auto;
}
.klantportaal-linklist__item__icon {
  flex: none;
  color: var(--color-orange);
  font-size: 32px;
  width: 45px;
}
.klantportaal-linklist__item__arrow {
  flex: none;
  font-size: 24px;
}
.klantportaal-linklist__item__link:hover .klantportaal-linklist__item__title, .klantportaal-linklist__item__link:focus .klantportaal-linklist__item__title {
  text-decoration: underline;
  text-underline-position: under;
}

.klantportaal-mijnwoonplaats__quicklinks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.klantportaal-mijnwoonplaats__quicklinks__link {
  flex: 1 1 25%;
  text-decoration: none;
  background-color: var(--color-blue-lighter);
  max-width: calc(25% - 15px);
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 30px 15px 20px;
  font-weight: 700;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(158, 169, 207, 0.35);
  margin: 15px 0;
}
.klantportaal-mijnwoonplaats__quicklinks__link i {
  margin-bottom: 15px;
  font-size: 36px;
  color: var(--color-orange);
  transition: color 300ms;
}
.klantportaal-mijnwoonplaats__quicklinks__link:hover i, .klantportaal-mijnwoonplaats__quicklinks__link:focus i {
  color: var(--color-orange-hover);
}
.klantportaal-mijnwoonplaats__quicklinks__link:not([href]) {
  opacity: 0.4;
}
@media (max-width: 800px) {
  .klantportaal-mijnwoonplaats__quicklinks {
    font-size: 14px;
  }
  .klantportaal-mijnwoonplaats__quicklinks__link {
    flex-basis: 50%;
    max-width: calc(50% - 15px);
  }
}
.klantportaal-mijnwoonplaats__banner {
  position: relative;
  background-color: var(--color-blue-lighter);
  border-radius: 8px;
  margin: 30px 0;
  padding: 20px 20px 35px;
  z-index: 0;
}
.klantportaal-mijnwoonplaats__banner__text {
  width: 65%;
}
.klantportaal-mijnwoonplaats__banner p {
  font-size: 16px;
}
.klantportaal-mijnwoonplaats__banner .button {
  text-transform: uppercase;
}
.klantportaal-mijnwoonplaats__banner--disabled {
  opacity: 0.4;
}
.klantportaal-mijnwoonplaats__banner__image {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  display: flex;
  align-items: flex-end;
}
.klantportaal-mijnwoonplaats__banner__image img {
  max-height: 100%;
  width: auto;
  border-radius: 0 8px 8px 0;
}
@media (max-width: 500px) {
  .klantportaal-mijnwoonplaats__banner__text {
    width: 100%;
  }
  .klantportaal-mijnwoonplaats__banner__image {
    display: none;
  }
}

.klantportaalgrid {
  width: 100%;
  max-width: var(--content-xwide);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [contentpadding-start] 1fr [sidecol] 3fr [content-start] 8.8fr [content-end] 1fr [contentpadding-end];
}
@media (max-width: 1440px) {
  .klantportaalgrid {
    grid-template-columns: [contentpadding-start] 7vw [sidecol] 3fr [content-start] 8.8fr [content-end] 7vw [contentpadding-end];
  }
}

.klantportaal-background {
  grid-area: 2/contentpadding-start/3/contentpadding-end;
  background-color: var(--color-blue-lighter);
  position: relative;
  z-index: -1;
  border-radius: 8px 8px 0 0;
}

.klantportaal-usernav__itemlist,
.klantportaal-sidenav__itemlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.klantportaal-usernav a,
.klantportaal-sidenav a {
  color: inherit;
}

.klantportaal-usernav {
  grid-area: 1/sidecol/2/content-start;
  padding: 40px 20px 15px 15px;
  font-size: 17px;
}
.klantportaal-usernav a {
  text-decoration: none;
  display: flex;
  align-items: baseline;
}
.klantportaal-usernav__item__icon {
  font-size: 20px;
  flex: none;
  width: 35px;
}
.klantportaal-usernav__item--logout {
  font-size: 14px;
  color: #6F67AD;
}
.klantportaal-usernav__item--logout span {
  text-decoration: underline;
}

.klantportaal-sidenav {
  grid-area: 2/sidecol/3/content-start;
  padding: 40px 20px 40px 0;
  position: relative;
}
.klantportaal-sidenav__mobiletoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: none;
  position: absolute;
  top: 30px;
  right: 10px;
  bottom: 30px;
  left: 10px;
  padding: 0 15px;
  font-size: 18px;
}
.klantportaal-sidenav__item {
  background-color: #fff;
}
.klantportaal-sidenav__item__link {
  font-size: 16px;
  display: block;
  text-decoration: none;
  border-radius: 8px;
  padding: 12px 15px;
  transition: background-color 300ms;
  display: flex;
  align-items: baseline;
  line-height: 24px;
  color: inherit;
}
.klantportaal-sidenav__item__link:focus, .klantportaal-sidenav__item__link:hover {
  background-color: #fff;
}
.klantportaal-sidenav__item__link:not([href]) {
  opacity: 0.4;
}
.klantportaal-sidenav__item__title {
  flex: 1 1 auto;
}
.klantportaal-sidenav__item__icon {
  flex: none;
  width: 35px;
  font-size: 14px;
}
.klantportaal-sidenav__item--mywp .klantportaal-sidenav__item__icon {
  opacity: 0.4;
  font-size: 16px;
}
.klantportaal-sidenav__item--active .klantportaal-sidenav__item__link {
  background-color: #fff;
  font-weight: 700;
}
.klantportaal-sidenav__item--active .klantportaal-sidenav__item__icon {
  text-shadow: 0 0 1px var(--color-blue);
}
@media (min-width: 999px) {
  .klantportaal-sidenav__item--logout {
    display: none;
  }
}

@media (max-width: 1000px) {
  .klantportaal-background {
    display: none;
  }
  .klantportaalgrid {
    display: block;
  }
  .klantportaal-usernav {
    display: none;
  }
  .klantportaal-sidenav {
    background-color: var(--color-blue-lighter);
    padding: 30px 10px;
    border-radius: 4px;
  }
  .klantportaal-sidenav__mobiletoggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .klantportaal-sidenav__item {
    display: none;
  }
  .klantportaal-sidenav__item__link {
    padding: 12px 10px;
  }
  .klantportaal-sidenav__item--mywp .klantportaal-sidenav__item__icon {
    opacity: 1;
  }
  .klantportaal-sidenav__item:not(.klantportaal-sidenav__item--mywp) .klantportaal-sidenav__item__icon {
    order: 2;
    width: 20px;
    text-align: right;
    display: none;
  }
  .klantportaal-sidenav__item:not(.klantportaal-sidenav__item--mywp) .klantportaal-sidenav__item__link::before {
    font-family: "coolicons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    flex: none;
    color: var(--color-orange);
    width: 35px;
    font-size: 20px;
    align-self: center;
    content: "\e972";
  }
  .klantportaal-sidenav__item--mijn-account .klantportaal-sidenav__item__link::before, .klantportaal-sidenav__item--mijn-gegevens .klantportaal-sidenav__item__link::before {
    content: "\ea0d" !important;
  }
  .klantportaal-sidenav__item--mijn-huis .klantportaal-sidenav__item__link::before {
    content: "\e915" !important;
  }
  .klantportaal-sidenav__item--betalen .klantportaal-sidenav__item__link::before {
    content: "\e9d4" !important;
  }
  .klantportaal-sidenav__item--logout .klantportaal-sidenav__item__link::before {
    content: "\ea08" !important;
    color: var(--color-blue) !important;
  }
  .klantportaal-sidenav__item--active {
    display: block;
  }
}
.dwp2023-dialog .klantportaal-sidenav__item {
  display: block;
}
.dwp2023-dialog .klantportaal-sidenav__item .klantportaal-sidenav__item__icon {
  display: block;
}
.dwp2023-dialog .klantportaal-sidenav__item + .klantportaal-sidenav__item {
  border-top: 1px solid var(--color-blue-lighter);
}

.klantportaal-contentheader {
  margin-top: 20px;
  grid-area: 1/content-start/2/content-end;
  box-shadow: 0 0 12px 0 rgba(158, 169, 207, 0.2);
  padding: 30px 60px;
  border-radius: 8px 8px 0 0;
  display: flex;
  min-height: 105px;
  align-items: center;
}
.klantportaal-contentheader > *:last-child {
  margin-bottom: 0;
}

.klantportaal-contentbody {
  position: relative;
  grid-area: 2/content-start/3/content-end;
  box-shadow: 0 0 12px 0 rgba(158, 169, 207, 0.2);
  padding: 30px 60px;
  background-color: #fff;
  margin-bottom: 100px;
  border-radius: 0 0 8px 8px;
}
.klantportaal-contentbody::before { /* mask top shadow */
  position: absolute;
  content: "";
  display: block;
  height: 12px;
  top: -12px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid var(--color-blue-lighter);
}
html.klantportaal-landingpage .klantportaal-contentbody {
  padding-top: 0;
  margin-top: -15px;
}
html.klantportaal-landingpage .klantportaal-contentbody::before {
  border-bottom: 0 none;
}
.klantportaal-contentbody > *:not(.fullwidth) {
  max-width: var(--content-xsmall);
}
.klantportaal-contentbody a:not(.button) {
  color: inherit;
}
.klantportaal-contentbody * + h2,
.klantportaal-contentbody * + h3 {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .klantportaal-contentheader,
  .klantportaal-contentbody {
    box-shadow: none;
    padding: 0;
  }
}
.klantportaalgesloten {
  color: #ff0000;
  text-align: center;
}

.klantportaal-disclaimer {
  margin-top: 20px;
  font-style: italic;
  font-size: 90%;
}